import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { useGetTopicsQuery } from '@common/services/api/profileServices/otherTopics';
import { useSaveUserProfileMutation } from '@common/services/api/profileServices/userProfile';
import chevronLeft from '../../common/assets/images/mygrdc/chevron-left.svg';
import {
  TopicCategoryInfo,
  TopicDetailsInfo,
} from '@common/services/api/profileServices/_profileService';
import Spinner from '@components/spinner';
import { useSelector } from 'react-redux';
import { selectAccessToken } from '@common/store/authenticationSlice';

const TopicsSelection: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [saveUserProfile] = useSaveUserProfileMutation();

  const { topicsData } = location.state || {}; // Preselected topics passed via navigation state
  const token = useSelector(selectAccessToken); // Access token from Redux

  const {
    data: topicTypes,
    isLoading,
    refetch,
  } = useGetTopicsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const [selectedTopics, setSelectedTopics] = useState<string[]>([]);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (token) {
      refetch();
    }
  }, [token, refetch]);

  useEffect(() => {
    if (Array.isArray(topicsData)) {
      setSelectedTopics(topicsData);
    } else if (topicTypes) {
      setSelectedTopics([]);
    }
  }, [topicsData, topicTypes]);

  const handleClearSelection = () => setSelectedTopics([]);

  const toggleTopicSelection = (topicId: string) => {
    setSelectedTopics((prev) =>
      prev.includes(topicId)
        ? prev.filter((id) => id !== topicId)
        : [...prev, topicId]
    );
  };

  const handleSaveChanges = async () => {
    const topicsPayload = { topics: selectedTopics };

    setIsSaving(true);

    try {
      const response = await saveUserProfile(topicsPayload);

      navigate('/profileSummary/preferences', {
        state: { updatedTopics: selectedTopics },
      });
    } catch (error) {
      console.error('Error updating topics:', error);
    }
  };

  if (isSaving) {
    return <Spinner />;
  }

  if (isLoading || !topicTypes) {
    return <Spinner />;
  }

  return (
    <div className="p-6">
      <button
        id="back-to-profile-button"
        className="mb-6 flex items-center"
        onClick={() => navigate('/profileSummary/preferences')}
      >
        <img
          id="back-chevron-icon"
          src={chevronLeft}
          alt="Chevron Right"
          className="w-5 h-5"
        />
        <p
          id="back-to-profile-text"
          className="text-primary-500 font-bold ml-2 text-left"
        >
          Back to preferences and interests
        </p>
      </button>

      <h1 className="text-2xl font-bold mb-2">
        What topics are you interested in?
      </h1>
      <p className="text-neutral-800 mb-6 font-regular">
        Select topics you’re interested in to personalise your MyGRDC experience
        even further. You can select as many as you like, we recommend 5-20. You
        can always change this later.
      </p>

      {topicTypes.map((category: TopicCategoryInfo) => {
        if (!category.refId || !category.refText || !category.topics)
          return null;

        return (
          <div key={category.refId} className="space-y-6 max-w-[70rem]">
            <div key={category.refId} className="mb-6">
              <h2 className="text-lg font-bold mb-4">{category.refText}</h2>
              <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
                {category.topics.map((topic: TopicDetailsInfo) => {
                  if (!topic.refId || !topic.refText) return null;

                  const isSelected = selectedTopics.includes(topic.refId);

                  return (
                    <div
                      key={topic.refId}
                      className={clsx(
                        'flex items-center justify-center h-16 border-2 cursor-pointer rounded-xl ',
                        isSelected
                          ? 'bg-primary-400 text-white border-primary-500'
                          : 'text-black border-2 border-primaryTeal-200'
                      )}
                      onClick={() => toggleTopicSelection(topic.refId ?? '')}
                    >
                      <div
                        className={clsx(
                          isSelected
                            ? 'font-bold'
                            : 'font-regular text-neutral-800'
                        )}
                      >
                        {topic.refText}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}

      <div className="fixed bottom-0 left-0 right-0 bg-white p-4 shadow-top z-50 border h-28">
        <div className="flex flex-col sm:flex-row sm:justify-between items-center h-full gap-4">
          {/* Selected Crops Text */}
          <div className="text-lg font-bold text-center sm:text-left md:pl-[250px] lg:pl-[350px] xl:pl-[490px]">
            {`${selectedTopics.length} crop${
              selectedTopics.length === 1 ? '' : 's'
            } selected`}
          </div>

          {/* Buttons */}
          <div className="flex justify-center sm:justify-end items-center gap-4">
            <button
              className="px-6 py-2 border-2 font-bold text-primary-400 border-primary-400 rounded-lg"
              onClick={handleClearSelection}
            >
              Clear
            </button>
            <button
              className={clsx(
                'px-6 py-2 font-bold rounded-lg',
                selectedTopics.length === 0
                  ? 'bg-neutral-400 text-white cursor-not-allowed'
                  : 'bg-primary-400 text-white cursor-pointer'
              )}
              onClick={handleSaveChanges}
              disabled={selectedTopics.length === 0}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopicsSelection;
