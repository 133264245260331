import React from 'react';

const FilterSidebarSkeleton: React.FC = () => {
  return (
    <div
      id="filter-sidebar-skeleton"
      className="w-full h-screen flex flex-col rounded-lg -mt-[4.0rem] xl:-ml-24 p-4"
    >
      {/* Skeleton for the Refine Heading */}
      <div
        id="skeleton-refine-heading"
        className="h-8 w-24 bg-neutral-200 bg-greyShimmer animate-shimmer rounded mb-4"
      ></div>

      {/* Skeleton for Personalise Search */}
      <div
        id="skeleton-personalise-search"
        className="mb-6 bg-neutral-200 bg-greyShimmer animate-shimmer h-24 p-4 rounded-md"
      >
        <div
          id="skeleton-personalise-title"
          className="h-6 w-32 bg-neutral-200 bg-greyShimmer animate-shimmer rounded mb-2"
        ></div>
        <div
          id="skeleton-personalise-description"
          className="h-4 w-48 bg-neutral-200 bg-greyShimmer animate-shimmer rounded"
        ></div>
      </div>

      {/* Skeleton for Filter Sections */}
      <div
        id="skeleton-filter-sections"
        className="flex-1 overflow-y-auto pr-2"
      >
        {Array.from({ length: 9 }).map((_, index) => (
          <div key={index} className="mb-6 border-b pb-4">
            {/* Section Heading Skeleton */}
            <div className="flex justify-between items-center mb-2">
              <div className="h-6 w-56 bg-neutral-200 bg-greyShimmer animate-shimmer rounded"></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FilterSidebarSkeleton;
