import React, { useState, useEffect } from 'react';
import primaryLogo from '../../common/assets/images/mygrdc/GRDC_Logo_Primary.png';
import greenUpArrow from '../../common/assets/images/mygrdc/greenUpArrow.svg';

const Footer: React.FC = () => {
  const [isScrollable, setIsScrollable] = useState(false);

  // Handle scroll event to show "Top" button only when scrolled down
  useEffect(() => {
    const handleScroll = () => {
      // Show the "Top" button when scrolled down more than 200px
      if (window.scrollY > 200) {
        setIsScrollable(true);
      } else {
        setIsScrollable(false);
      }
    };

    // Attach scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Function to scroll to the top smoothly
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div>
      {/* Top Button Section */}
      <div className="bg-primary-400 h-1 relative mt-8 z-10">
        {isScrollable && (
          <button
            onClick={scrollToTop}
            className="absolute lg:right-24 md:right-8 top-1/2 transform -translate-y-1/2 bg-white text-primary-400 border-2 border-primary-400 px-4 py-2 rounded-lg shadow-md flex items-center space-x-1 text-left text-sm font-bold leading-5 lg:translate-x-0 lg:left-auto left-1/2 transform -translate-x-1/2 md:px-2 md:py-1 md:text-xs md:space-x-0.5 md:-translate-x-1/2 md:left-auto lg:px-5 lg:py-3 lg:text-sm lg:space-x-1"
          >
            <img
              src={greenUpArrow}
              alt="Up Arrow"
              className="w-5 h-5 md:w-4 md:h-4"
            />
            <span>Top</span>
          </button>
        )}
      </div>

      {/* Footer Section */}
      <div className="shadow-lg footer bg-white py-4 font-regular">
        <div className="container flex flex-col items-center justify-between md:flex-col lg:flex-row p-4 mx-auto">
          {/* Logo - Centered on smaller screens */}
          <div className="flex justify-center mb-8 lg:mb-0 lg:-mt-12 w-full lg:w-auto">
            <div className="w-[212px] h-[108px] mx-auto">
              <img
                src={primaryLogo}
                alt="GRDC Logo"
                className="object-contain w-full h-full"
              />
            </div>
          </div>

          {/* Right Section with Text and Links */}
          <div className="flex flex-col text-sm space-y-4 text-center md:text-left">
            {/* Acknowledgement Text */}
            <p className="text-base leading-[19.5px] mb-4 mt-4">
              In the spirit of reconciliation GRDC acknowledges the Traditional
              Custodians of country throughout Australia and their connections
              to land, sea, and community. We pay our respects to their Elders
              past, present, and emerging, and extend that respect to all
              Aboriginal and Torres Strait Islander peoples today.
            </p>

            {/* Copyright */}
            <p className="text-xs md:text-sm font-regular mb-4">
              © Grains Research and Development Corporation{' '}
              {new Date().getFullYear()}
            </p>

            {/* Links - Stack on smaller screens, inline on larger screens */}
            <div className="flex flex-col items-center space-y-2 md:space-y-0 md:flex-row md:space-x-4 lg:space-x-8">
              <a
                href="https://grdc.com.au/policies/privacy"
                className="font-bold text-xs md:text-sm lg:text-base text-primary-400 underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy
              </a>
              <span className="hidden md:inline-block">|</span>
              <a
                href="https://grdc.com.au/policies/copyright"
                className="font-bold text-xs md:text-sm lg:text-base text-primary-400 underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Copyright
              </a>
              <span className="hidden md:inline-block">|</span>
              <a
                href="https://grdc.com.au/policies/terms-of-use"
                className="font-bold text-xs md:text-sm lg:text-base text-primary-400 underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Use and Disclaimer
              </a>
              <span className="hidden md:inline-block">|</span>
              <a
                href="https://grdc.com.au/about/contact-us"
                className="font-bold text-xs md:text-sm lg:text-base text-primary-400 underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact Us
              </a>
              <span className="hidden md:inline-block">|</span>
              <a
                href="https://groundcover.grdc.com.au/advertise"
                className="font-bold text-xs md:text-sm lg:text-base text-primary-400 underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Advertise
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
