import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '@common/store/store';
import COMMON_PROPERTIES from '@common/properties/common-properties';
import {
  ContentSearchServiceClient,
  SearchContentRequest,
  SearchResponseBasic,
} from './_contentSearchService';

const baseAPIUrl = COMMON_PROPERTIES.baseAPIUrl;
const subscriptionKey = COMMON_PROPERTIES.subscriptionKey || '';
const client = new ContentSearchServiceClient(
  baseAPIUrl + '/contentsearchservice'
);

export const searchContentApi = createApi({
  reducerPath: 'searchContentApi',
  tagTypes: ['SearchContent'],
  baseQuery: fetchBaseQuery(),
  endpoints: (builder) => ({
    searchContent: builder.query<SearchResponseBasic, SearchContentRequest>({
      queryFn: async (
        body,
        { getState }
      ): Promise<{ data?: SearchResponseBasic; error?: any }> => {
        const state = getState() as RootState;
        const token: string = state.authentication.accessToken || '';

        if (!token) {
          return { error: { status: 401, data: 'No token available' } };
        }

        try {
          const searchResponse = await client.executeSearch(
            body,
            token,
            subscriptionKey
          );
          return { data: searchResponse };
        } catch (error: any) {
          return {
            error: {
              status: error.response?.status || 500,
              data: error.response?.data || 'Unknown error occurred',
            },
          };
        }
      },
      providesTags: ['SearchContent'],
    }),
  }),
});

export const { useSearchContentQuery } = searchContentApi;
