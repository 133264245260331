const EventsSkeletons: React.FC = () => {
  const skeletonItems = Array.from({ length: 2 });

  return (
    <div className="relative min-h-full overflow-hidden">
      {/* Main Container */}
      <div className="container relative mx-auto px-4 sm:px-6 lg:px-8 mb-12">
        {/* Header Section */}
        <div className="flex flex-col sm:flex-row justify-start sm:justify-between mt-16 sm:mt-24 lg:mt-32">
          {/* Skeleton for "Events" heading */}
          <div className="bg-secondaryTeal-100 bg-cover bg-no-repeat h-8 w-1/6 rounded bg-shimmer animate-shimmer"></div>

          {/* Skeleton for "See all events" button */}
          <div className="bg-secondaryTeal-100 hidden sm:inline-block h-10 w-40 rounded bg-cover bg-no-repeat bg-shimmer animate-shimmer"></div>
        </div>

        {/* Skeleton for Upcoming and Past Sections */}
        <div className="hidden sm:block mt-8 sm:mt-12">
          {/* Skeleton for "Upcoming" heading */}
          <div className="bg-secondaryTeal-100 h-6 w-1/6 rounded mt-12 mb-6 bg-cover bg-no-repeat bg-shimmer animate-shimmer"></div>

          {/* Skeleton grid for upcoming events */}
          <div className="grid gap-6 sm:grid-cols-2">
            {skeletonItems.map((_, index) => (
              <div
                key={`upcoming-${index}`}
                className="bg-secondaryTeal-100 h-48 rounded-lg bg-cover bg-no-repeat bg-shimmer animate-shimmer"
              ></div>
            ))}
          </div>

          {/* Skeleton for "Past" heading */}
          <div className="bg-secondaryTeal-100 h-6 w-1/6 rounded mt-12 mb-6 bg-cover bg-no-repeat bg-shimmer animate-shimmer"></div>

          {/* Skeleton grid for past events */}
          <div className="grid gap-6 sm:grid-cols-2">
            {skeletonItems.map((_, index) => (
              <div
                key={`past-${index}`}
                className="bg-secondaryTeal-100 h-48 rounded-lg bg-cover bg-no-repeat bg-shimmer animate-shimmer"
              ></div>
            ))}
          </div>
        </div>

        {/* Skeleton for Tabs (Small Screens) */}
        <div className="sm:hidden mt-6">
          <div className="flex justify-center border-b-2">
            {skeletonItems.map((_, index) => (
              <div
                key={`tab-${index}`}
                className="bg-secondaryTeal-100 h-8 w-24 rounded mx-2 bg-cover bg-no-repeat bg-shimmer animate-shimmer"
              ></div>
            ))}
          </div>
        </div>

        {/* Skeleton for Tab Content (Small Screens) */}
        <div className="sm:hidden mt-6">
          {skeletonItems.map((_, index) => (
            <div
              key={`tab-content-${index}`}
              className="bg-secondaryTeal-100 h-36 rounded-lg mb-6 bg-cover bg-no-repeat bg-shimmer animate-shimmer"
            ></div>
          ))}
        </div>

        {/* Skeleton for Button (Small Screens) */}
        <div className="flex justify-center sm:hidden mt-8">
          <div className="bg-secondaryTeal-100 h-10 w-full rounded bg-cover bg-no-repeat bg-shimmer animate-shimmer"></div>
        </div>
      </div>
    </div>
  );
};

export default EventsSkeletons;
