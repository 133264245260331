import React, { useState } from 'react';

import chevronUp from '../common/assets/images/mygrdc/chevron-up.svg';
import chevronDown from '../common/assets/images/mygrdc/chevron-down.svg';

interface SortingDropdownProps {
  selectedOption: string;
  sortingOptions: { label: string; value: number }[];
  onSelectOption: (option: { label: string; value: number }) => void;
}

const SortingDropdown: React.FC<SortingDropdownProps> = ({
  selectedOption,
  sortingOptions,
  onSelectOption,
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  return (
    <div
      id="sorting-dropdown"
      className="relative"
      onMouseEnter={() => setIsDropdownVisible(true)}
      onMouseLeave={() => setIsDropdownVisible(false)}
    >
      <div className="text-device-green font-bold py-2 px-3 cursor-pointer flex items-center">
        {selectedOption}
        <img
          src={isDropdownVisible ? chevronUp : chevronDown}
          alt="Toggle Dropdown"
          className="ml-2 w-4 h-4"
        />
      </div>
      {isDropdownVisible && (
        <div className="absolute z-10 w-44 bg-shades-white rounded-lg shadow-lg right-0">
          {sortingOptions.map((option) => (
            <div
              key={option.value}
              className="px-4 py-2 cursor-pointer hover:bg-primaryTeal-100 font-regular"
              onClick={() => onSelectOption(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SortingDropdown;
