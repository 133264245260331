import React from 'react';

interface PageHeadingProps {
  title: string;
  description: string;
}

const PageHeading: React.FC<PageHeadingProps> = ({ title, description }) => (
  <div
    id="page-heading"
    className="text-left mt-8 px-16 md:px-68 lg:px-68 xl:px-72"
  >
    <h2 className="text-4xl font-bold">{title}</h2>
    <p className="mt-4 font-regular">{description}</p>
  </div>
);

export default PageHeading;
