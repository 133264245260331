import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  CropCategoryInfo,
  ProfileServiceClient,
  ReferenceInfoRequest,
  ReferenceInfoResponse,
} from '@common/services/api/profileServices/_profileService';
import { RootState } from '@common/store/store';
import COMMON_PROPERTIES from '@common/properties/common-properties';

const baseAPIUrl = COMMON_PROPERTIES.baseAPIUrl;
const subscriptionKey = COMMON_PROPERTIES.subscriptionKey || '';
const client = new ProfileServiceClient(baseAPIUrl + '/profileservice');

export const cropsApi = createApi({
  reducerPath: 'cropsApi',
  baseQuery: fetchBaseQuery(),
  endpoints: (builder) => ({
    getCrops: builder.query<CropCategoryInfo[], void>({
      queryFn: async (
        _,
        { getState }
      ): Promise<{ data?: CropCategoryInfo[]; error?: any }> => {
        const state: RootState = getState() as RootState;
        const token: string = state.authentication.accessToken || '';

        if (!token) {
          return { error: { status: 401, data: 'No token available' } };
        }

        try {
          const availableCrops = await client.getAvailableCrops(
            token,
            subscriptionKey
          );
          return { data: availableCrops.map((crop) => crop) };
        } catch (error: any) {
          return {
            error: {
              status: error.response?.status || 500,
              data: error.response?.data || 'Unknown error occurred',
            },
          };
        }
      },
    }),

    getCropsInfo: builder.query<
      ReferenceInfoResponse,
      ReferenceInfoRequest | null
    >({
      queryFn: async (
        body,
        { getState }
      ): Promise<{ data?: ReferenceInfoResponse; error?: any }> => {
        const state: RootState = getState() as RootState;
        const token: string = state.authentication.accessToken || '';

        if (!token) {
          return { error: { status: 401, data: 'No token available' } };
        }

        try {
          const cropsInfo = await client.getCropsInfo(
            body,
            token,
            subscriptionKey
          );
          return { data: cropsInfo };
        } catch (error: any) {
          return {
            error: {
              status: error.response?.status || 500,
              data: error.response?.data || 'Unknown error occured',
            },
          };
        }
      },
    }),
  }),
});

export const { useGetCropsQuery, useGetCropsInfoQuery } = cropsApi;
