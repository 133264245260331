import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { useGetCropsQuery } from '@common/services/api/profileServices/crops';
import { useSaveUserProfileMutation } from '@common/services/api/profileServices/userProfile';
import chevronLeft from '../../common/assets/images/mygrdc/chevron-left.svg';
import Spinner from '@components/spinner';
import { useSelector } from 'react-redux';
import { selectAccessToken, setToken } from '@common/store/authenticationSlice';

const CropsSelection: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [saveUserProfile] = useSaveUserProfileMutation();

  const { cropsData } = location.state || {};
  const token = useSelector(selectAccessToken);

  const [hasInitialized, setHasInitialized] = useState(false);

  const {
    data: availableCrops,
    isLoading,
    refetch,
  } = useGetCropsQuery(undefined, {
    skip: !token,
    refetchOnMountOrArgChange: true,
  });

  const [selectedCrops, setSelectedCrops] = useState<string[]>([]);
  const [isSaving, setIsSaving] = useState(false); // Track saving state

  useEffect(() => {
    if (token) {
      refetch();
    }
  }, [token, refetch]);

  useEffect(() => {
    if (!hasInitialized) {
      setSelectedCrops(Array.isArray(cropsData) ? cropsData : []);
      setHasInitialized(true);
    }
  }, [hasInitialized]);

  const handleClearSelection = () => {
    setSelectedCrops([]);
    setHasInitialized(true);
  };

  const toggleCropSelection = (cropId: string) => {
    setSelectedCrops((prev) =>
      prev.includes(cropId)
        ? prev.filter((id) => id !== cropId)
        : [...prev, cropId]
    );
  };

  const handleSaveChanges = async () => {
    const cropsPayload = { crops: selectedCrops };
    setIsSaving(true);

    try {
      const response = await saveUserProfile(cropsPayload);

      navigate('/profileSummary/preferences', {
        state: { updatedCrops: selectedCrops },
      });
    } catch (error) {
      console.error('Error updating crops:', error);
    }
  };

  if (isSaving) {
    return <Spinner />;
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="p-6">
      <button
        className="mb-6 flex items-center"
        onClick={() => navigate('/profileSummary/preferences')}
      >
        <img src={chevronLeft} alt="Back" className="w-5 h-5" />
        <p className="text-primary-500 font-bold ml-2 text-left ">
          Back to preferences and interests
        </p>
      </button>
      <h1 className="text-2xl font-bold mb-2">
        What crops are you interested in?
      </h1>
      <p className="text-neutral-800 mb-6 font-regular">
        Select at least one crop to personalise your MyGRDC experience.
      </p>

      {availableCrops?.map((category) => (
        <div key={category.refId} className="mb-6">
          <h2 className="text-lg font-bold mb-4">{category.refText}</h2>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
            {category.crops?.map((crop) => {
              const isSelected = selectedCrops.includes(crop.refId || '');
              return (
                <div
                  key={crop.refId}
                  className={clsx(
                    'flex items-center justify-center h-16 px-2 border-2 cursor-pointer rounded-xl overflow-hidden text-center',
                    isSelected
                      ? 'bg-primary-400 text-white border-primary-500'
                      : 'text-black border-primaryTeal-200'
                  )}
                  onClick={() => toggleCropSelection(crop.refId || '')}
                >
                  <div
                    className={clsx(
                      'truncate',
                      isSelected ? 'font-bold' : 'font-regular text-neutral-800'
                    )}
                  >
                    {crop.refText}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ))}

      <div className="fixed bottom-0 left-0 right-0 bg-white p-4 shadow-top z-50 border h-28">
        <div className="flex flex-col sm:flex-row sm:justify-between items-center h-full gap-4">
          <div className="text-lg font-bold text-center sm:text-left md:pl-[250px] lg:pl-[350px] xl:pl-[490px]">
            {`${selectedCrops.length} crop${
              selectedCrops.length === 1 ? '' : 's'
            } selected`}
          </div>

          <div className="flex justify-center sm:justify-end items-center gap-4">
            <button
              className="px-6 py-2 border-2 font-bold text-primary-400 border-primary-400 rounded-lg"
              onClick={handleClearSelection}
            >
              Clear
            </button>
            <button
              className={clsx(
                'px-6 py-2 font-bold rounded-lg',
                selectedCrops.length === 0
                  ? 'bg-neutral-400 text-white cursor-not-allowed'
                  : 'bg-primary-400 text-white cursor-pointer'
              )}
              onClick={handleSaveChanges}
              disabled={selectedCrops.length === 0}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CropsSelection;
