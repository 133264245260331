import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPersona,
  incrementStep,
  setPersona,
} from '@common/store/registrationSlice';
import OptionCard from './options';
import RegistrationTitle from './registrationTitle';
import RegistrationSubtitle from './registrationSubtitle';
import Spinner from '@components/spinner';
import Footer from './footer';
import Button from '@components/button';
import { useGetUserTypesQuery } from '@common/services/api/profileServices/userTypes';
import { useGetUserProfileQuery } from '@common/services/api/profileServices/userProfile';

const DescribeYourselfStep = () => {
  const dispatch = useDispatch();
  const persona = useSelector(getPersona);

  const { data: personaOptions, isLoading } = useGetUserTypesQuery();
  const { data: userProfile } = useGetUserProfileQuery();

  useEffect(() => {
    if (!persona.userType?.refId && userProfile?.userType) {
      dispatch(
        setPersona({
          userType: {
            refId: userProfile.userType,
            refText: personaOptions?.find(
              (option) => option.refId === userProfile.userType
            )?.refText,
          },
        })
      );
    }
  }, [userProfile, personaOptions, persona.userType, dispatch]);

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setPersona({
        userType: {
          refId: event.target.value,
          refText: event.target.name,
        },
      })
    );
  };

  const displayActiveNextBtn = persona.userType?.refId;

  if (!personaOptions || isLoading) {
    return <Spinner />;
  }

  return (
    <div className="mt-6">
      <RegistrationTitle title="How would you describe yourself?" />
      <RegistrationSubtitle subtitle="Select the option which describes you best. You can always change this later." />
      <div className="grid grid-cols-2 gap-6 mt-4">
        {personaOptions?.map((option) => {
          return (
            <OptionCard
              key={option.refId}
              label={option.refText}
              name={option.refText}
              value={option.refId}
              checked={persona.userType?.refId === option.refId}
              onChange={handleOptionChange}
            />
          );
        })}
      </div>

      <Footer>
        <div className="flex justify-end h-full">
          <div className="flex items-center gap-5 ">
            {displayActiveNextBtn ? (
              <Button
                title="Next"
                type="filled"
                onClick={() => dispatch(incrementStep())}
              />
            ) : (
              <Button title="Next" type="disabled" />
            )}
          </div>
        </div>
      </Footer>
    </div>
  );
};

export default DescribeYourselfStep;
