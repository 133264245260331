import { useState } from 'react';
import grdcLogo from '../common/assets/images/mygrdc/mygrdc_logo.svg';
import closeIconGrey from '../common/assets/images/mygrdc/close-outline-grey.svg';

const ScreenNotification = () => {
  const [isVisible, setIsVisible] = useState(true);

  return (
    <>
      {isVisible && (
        <div
          id="screen-notification"
          className="lg:hidden flex items-center justify-between w-full h-24 bg-neutral-100 p-4"
        >
          <button
            id="close-notification-btn"
            className="text-gray-500 hover:text-gray-700 mx-2"
            onClick={() => setIsVisible(false)}
            aria-label="Close notification"
          >
            <img
              src={closeIconGrey}
              alt="Search"
              width={29}
              height={29}
              id="close-notification-icon"
            />
          </button>

          <div id="notification-content" className="flex items-center">
            <div
              id="logo-container"
              className="w-36 h-20 bg-shades-white rounded-xl flex items-center justify-center shadow-md mx-4"
            >
              <img
                src={grdcLogo}
                alt="Logo"
                className="w-12 h-12 mx-4"
                id="notification-logo"
              />
            </div>

            <p
              id="notification-text-md"
              className="hidden sm:flex lg:hidden font-semiBold text-sm "
            >
              Your screen size is too small for the full experience. For the
              best experience, please use the MyGRDC smartphone app (coming
              soon) or a larger screen.
            </p>

            <p
              id="notification-text-sm"
              className="sm:hidden items-center font-semiBold text-sm pr-4"
            >
              Get the full MyGRDC experience in the app
              <br />
              <span className="font-semiBold text-sm text-primary-400">
                Open in app (coming soon)
              </span>
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default ScreenNotification;
