import React from 'react';
import ReactPaginate from 'react-paginate';
import chevronRightGreen from '../common/assets/images/mygrdc/chevron-right-green.svg';
import chevronLeft from '../common/assets/images/mygrdc/chevron-left.svg';

interface PaginationProps {
  currentPage: number;
  pageCount: number;
  onPageChange: (selected: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  pageCount,
  onPageChange,
}) => {
  const handlePageClick = (event: { selected: number }) => {
    onPageChange(event.selected);
  };

  const pageRangeDisplayed = window.innerWidth < 640 ? 2 : 5;

  return (
    <div className="flex items-center justify-between mt-4 space-x-2">
      <div>
        <button
          onClick={() => handlePageClick({ selected: currentPage - 1 })}
          disabled={currentPage === 0}
          className={`previous flex items-center font-bold ${
            currentPage === 0 ? 'text-neutral-500' : 'text-device-green'
          }`}
        >
          <img
            src={chevronLeft}
            alt="Previous"
            className={`w-5 h-5 mr-1 sm:w-5 sm:h-5 ${
              currentPage === 0 ? 'opacity-40' : ''
            }`}
          />
          <span className="hidden sm:inline">Previous</span>
        </button>
      </div>

      <div className="flex-1 text-center">
        <ReactPaginate
          breakLabel={'...'}
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={pageRangeDisplayed}
          onPageChange={handlePageClick}
          containerClassName={'pagination'}
          activeClassName={'active'}
          pageClassName={'page'}
          previousClassName={'hidden'}
          nextClassName={'hidden'}
          forcePage={currentPage}
        />
      </div>

      <div>
        <button
          onClick={() => handlePageClick({ selected: currentPage + 1 })}
          disabled={currentPage === pageCount - 1}
          className={`next flex items-center font-bold ${
            currentPage === pageCount - 1
              ? 'text-neutral-500'
              : 'text-device-green'
          }`}
        >
          <span className="hidden sm:inline">Next</span>
          <img
            src={chevronRightGreen}
            alt="Next"
            className={`w-6 h-6 ml-2 sm:w-6 sm:h-6  ${
              currentPage === pageCount - 1 ? 'opacity-40' : ''
            }`}
          />
        </button>
      </div>
    </div>
  );
};

export default Pagination;
