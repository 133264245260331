import { configureStore } from '@reduxjs/toolkit';
import authenticationReducer from './authenticationSlice';
import searchReducer from './searchSlice';
import onlineReducer from './onlineSlice';
import registrationReducer from './registrationSlice';
import { cropsApi } from '../services/api/profileServices/crops';
import { postCodeApi } from '../services/api/profileServices/postCode';
import { regionsApi } from '../services/api/profileServices/regions';
import { topicsApi } from '../services/api/profileServices/otherTopics';
import { userTypesApi } from '../services/api/profileServices/userTypes';
import { usersApi } from '../services/api/profileServices/userProfile';
import { latestReleasesApi } from '@common/services/api/searchServices/latestReleases';
import { latestVideosPodcastsApi } from '@common/services/api/searchServices/latestVideosPodcasts';
import { newsApi } from '@common/services/api/searchServices/news';
import { upcomingEventsApi } from '@common/services/api/searchServices/upcomingEvents';
import { pastEventsApi } from '@common/services/api/searchServices/pastEvents';
import { searchContentApi } from '@common/services/api/searchServices/searchContent';
import { facetsApi } from '@common/services/api/searchServices/facets';

export interface RootState {
  online: ReturnType<typeof onlineReducer>;
  registration: ReturnType<typeof registrationReducer>;
  authentication: ReturnType<typeof authenticationReducer>;
  search: ReturnType<typeof searchReducer>;
  [userTypesApi.reducerPath]: ReturnType<typeof userTypesApi.reducer>;
  [postCodeApi.reducerPath]: ReturnType<typeof postCodeApi.reducer>;
  [regionsApi.reducerPath]: ReturnType<typeof regionsApi.reducer>;
  [cropsApi.reducerPath]: ReturnType<typeof cropsApi.reducer>;
  [topicsApi.reducerPath]: ReturnType<typeof topicsApi.reducer>;
  [usersApi.reducerPath]: ReturnType<typeof usersApi.reducer>;
  [latestReleasesApi.reducerPath]: ReturnType<typeof latestReleasesApi.reducer>;
  [latestVideosPodcastsApi.reducerPath]: ReturnType<typeof latestVideosPodcastsApi.reducer>;
  [newsApi.reducerPath]: ReturnType<typeof newsApi.reducer>;
  [upcomingEventsApi.reducerPath]: ReturnType<typeof upcomingEventsApi.reducer>;
  [pastEventsApi.reducerPath]: ReturnType<typeof pastEventsApi.reducer>;
  [searchContentApi.reducerPath]: ReturnType<typeof searchContentApi.reducer>;
  [facetsApi.reducerPath]: ReturnType<typeof facetsApi.reducer>;
}

export const store = configureStore({
  reducer: {
    online: onlineReducer,
    registration: registrationReducer,
    authentication: authenticationReducer,
    search: searchReducer,
    [userTypesApi.reducerPath]: userTypesApi.reducer,
    [postCodeApi.reducerPath]: postCodeApi.reducer,
    [regionsApi.reducerPath]: regionsApi.reducer,
    [cropsApi.reducerPath]: cropsApi.reducer,
    [topicsApi.reducerPath]: topicsApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [latestReleasesApi.reducerPath]: latestReleasesApi.reducer,
    [latestVideosPodcastsApi.reducerPath]: latestVideosPodcastsApi.reducer,
    [newsApi.reducerPath]: newsApi.reducer,
    [upcomingEventsApi.reducerPath]: upcomingEventsApi.reducer,
    [pastEventsApi.reducerPath]: pastEventsApi.reducer,
    [searchContentApi.reducerPath]: searchContentApi.reducer,
    [facetsApi.reducerPath]: facetsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(userTypesApi.middleware)
      .concat(postCodeApi.middleware)
      .concat(regionsApi.middleware)
      .concat(cropsApi.middleware)
      .concat(topicsApi.middleware)
      .concat(usersApi.middleware)
      .concat(latestReleasesApi.middleware)
      .concat(latestVideosPodcastsApi.middleware)
      .concat(newsApi.middleware)
      .concat(upcomingEventsApi.middleware)
      .concat(pastEventsApi.middleware)
      .concat(searchContentApi.middleware)
      .concat(facetsApi.middleware),
});

export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;
