import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../common/assets/images/mygrdc/mygrdc_logo.svg';
import homeOutline from '../common/assets/images/mygrdc/home-outline.svg';
import homeFilled from '../common/assets/images/mygrdc/home-filled.svg';
import searchGray from '../common/assets/images/mygrdc/searchMenuGray.svg';
import searchGreen from '../common/assets/images/mygrdc/searchMenuGreen.svg';
import profileOutline from '../common/assets/images/mygrdc/profile-outline.svg';
import profileFilled from '../common/assets/images/mygrdc/profile-filled.svg';
import chevronUp from '../common/assets/images/mygrdc/chevron-up.svg';
import chevronDown from '../common/assets/images/mygrdc/chevron-down.svg';
import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import { useEffect, useId, useState } from 'react';
import clsx from 'clsx';
import CustomModal from './customModal';
import logoutIcon from '../common/assets/images/mygrdc/logout.svg';
import { useGetUserProfileQuery } from '@common/services/api/profileServices/userProfile';
import { useSelector } from 'react-redux';
import { selectAccessToken } from '@common/store/authenticationSlice';

interface SubMenuItem {
  label: string;
  link: string;
}

interface MenuItem {
  label: string;
  iconOutline: string;
  iconFilled: string;
  link?: string;
  subItems?: SubMenuItem[];
}

const NavbarWithMenu = () => {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const location = useLocation();
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const menuItems: MenuItem[] = [
    {
      label: 'Home',
      iconOutline: homeOutline,
      iconFilled: homeFilled,
      link: '/dashboard',
    },
    {
      label: 'Search',
      iconOutline: searchGray,
      iconFilled: searchGreen,
      link: '/search',
    },
    // {
    //   label: 'Saved',
    //   iconOutline: bookmarkOutline,
    //   iconFilled: bookmarkFilled,
    //   link: '/saved',
    // },
    {
      label: 'Profile',
      iconOutline: profileOutline,
      iconFilled: profileFilled,
      subItems: [
        { label: 'View Username', link: '' },
        { label: 'Profile', link: '/profileSummary' },
        { label: 'Log Out', link: '/logout' },
      ],
    },
  ];

  const getActiveItem = (): {
    mainItem: string | null;
    subItem: string | null;
  } => {
    for (const item of menuItems) {
      if (item.subItems) {
        // Check if a sub-item is active
        for (const subItem of item.subItems) {
          if (location.pathname === subItem.link) {
            return { mainItem: item.label, subItem: subItem.label };
          }
        }
      }
      // Check if the main item is active
      if (item.link && location.pathname === item.link) {
        return { mainItem: item.label, subItem: null };
      }

      // Check for Profile subpages (e.g., /profileSummary)
      if (
        item.label === 'Profile' &&
        location.pathname.startsWith('/profileSummary')
      ) {
        return { mainItem: 'Profile', subItem: null };
      }
    }
    return { mainItem: null, subItem: null };
  };

  const { mainItem: activeItem, subItem: activeSubItem } = getActiveItem();

  const handleMenuItemClick = (item: MenuItem, subItem?: SubMenuItem) => {
    if (subItem) {
      if (subItem.link === '/logout') {
        setIsModalOpen(true);
        return;
      }
      if (subItem.link === '/profileSummary') {
        navigate('/profileSummary');
      }
      navigate(subItem.link);
    } else if (item.link) {
      navigate(item.link);
    }
  };

  const confirmLogOut = () => {
    instance.logoutRedirect().catch((error: any) => console.log(error));
    return navigate('/');
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="shadow-md navbar">
      <div className="container flex items-center justify-between p-4 mx-auto">
        <img
          src={logo}
          alt="MyGRDC Logo"
          width={120}
          onClick={() => navigate('/dashboard')}
          className="cursor-pointer"
        />
        <AuthenticatedTemplate>
          <div className="flex gap-4 sm:gap-12">
            {menuItems.map((item) =>
              item.label === 'Home' ? (
                <div key={item.label} className="hidden sm:block">
                  <SingleMenuItem
                    item={item}
                    hoveredItem={hoveredItem}
                    activeItem={activeItem}
                    activeSubItem={activeSubItem}
                    setHoveredItem={setHoveredItem}
                    onClick={handleMenuItemClick}
                  />
                </div>
              ) : (
                <SingleMenuItem
                  key={item.label}
                  item={item}
                  hoveredItem={hoveredItem}
                  activeItem={activeItem}
                  activeSubItem={activeSubItem}
                  setHoveredItem={setHoveredItem}
                  onClick={handleMenuItemClick}
                />
              )
            )}
          </div>
          <CustomModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            title="Log Out?"
          >
            <>
              <div className="py-1 mb-1">
                <div className="flex items-center justify-between pb-2 mb-3 border-b"></div>
                <span className="font-regular text-right text-neutral-800">
                  Are you sure you want to log out?
                </span>
              </div>
              <div className="flex flex-col-reverse sm:flex-row justify-end gap-4 mt-8">
                <button
                  onClick={handleCancel}
                  className="px-6 py-2 border-2 border-primary-400 text-primary-400 font-semibold rounded-lg hover:bg-primary-50"
                >
                  Cancel
                </button>
                <button
                  onClick={confirmLogOut}
                  className="px-6 py-2 bg-primary-400 text-white font-semibold rounded-lg hover:bg-primary-500"
                >
                  Logout
                </button>
              </div>
            </>
          </CustomModal>
        </AuthenticatedTemplate>
      </div>
    </div>
  );
};

export default NavbarWithMenu;

const SingleMenuItem = ({
  item,
  hoveredItem,
  activeItem,
  activeSubItem,
  setHoveredItem,
  onClick,
}: {
  item: MenuItem;
  hoveredItem: string | null;
  activeItem: string | null;
  activeSubItem: string | null;
  setHoveredItem: (label: string | null) => void;
  onClick: (item: MenuItem, subItem?: SubMenuItem) => void;
}) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const id = useId();
  const isHovered = hoveredItem === item.label;

  const isActive = activeItem === item.label;

  const token = useSelector(selectAccessToken);
  // Fetch the user profile only if the token is available
  const { data: userProfile, refetch } = useGetUserProfileQuery(undefined, {
    skip: !token,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (token) {
      refetch();
    }
  }, [token, refetch]);

  // Handle hover logic to show the dropdown when hovering
  const handleMouseEnter = () => {
    setHoveredItem(item.label);
    setDropdownVisible(true);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
    setDropdownVisible(false);
  };

  return (
    <div
      id={`${id}-${item.label}`}
      key={item.label}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="relative flex items-start cursor-pointer z-40"
    >
      <div onClick={() => onClick(item)} className="flex items-start">
        {/* Icon for small screens (always green) */}
        <img
          src={item.iconFilled}
          width={24}
          alt={`${item.label} icon`}
          className={'mr-1 sm:hidden'}
        />

        {/* Icon for larger screens (changes based on hover/active) */}
        <img
          src={isActive || isHovered ? item.iconFilled : item.iconOutline}
          width={24}
          alt={`${item.label} icon`}
          className={'hidden sm:block mr-1'}
        />

        <span className="hidden sm:inline-block">
          <div
            className={clsx(
              'flex items-center gap-1 font-semibold tracking-wide',
              isActive || isHovered ? 'text-device-green' : 'text-neutral-600'
            )}
          >
            {/* Text with underline */}
            <span
              className={clsx(
                'inline-block', // Ensure only text gets the underline
                isActive
                  ? 'border-b-4 border-primary-400' // Green underline for active state
                  : 'hover:border-b-4 hover:border-primary-400 border-b-4 border-transparent'
              )}
            >
              {item.label}
            </span>

            {/* Chevron icon (separate, no underline) */}
            {item.subItems && (
              <img
                src={isHovered ? chevronUp : chevronDown}
                width={20}
                alt={`${item.label} icon`}
                className="ml-1" // Add spacing to separate from text
              />
            )}
          </div>
        </span>
      </div>

      {item.subItems && item.subItems.length > 0 && isDropdownVisible && (
        <div className="absolute left-0 right-0">
          <div className="h-10 w-full"></div>
          <div
            className={clsx(
              'absolute z-10 bg-white border rounded-lg shadow-lg shadow-top',
              'right-0',
              'w-auto min-w-32'
            )}
          >
            {item.subItems.map((subItem) => (
              <div
                id={`${id}-${subItem.label}`}
                key={subItem.label}
                className={clsx(
                  'flex items-center py-2 px-2 cursor-pointer',
                  'border-b-4 border-transparent',
                  ['Log Out', 'Profile'].includes(subItem.label)
                    ? 'w-full hover:bg-neutral-100 pl-4 rounded-b-lg'
                    : ''
                )}
                onClick={() => onClick(item, subItem)}
              >
                {renderSubItemLabel(subItem, userProfile)}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const renderSubItemLabel = (subItem: SubMenuItem, userProfile: any) => {
  switch (subItem.label) {
    case 'Log Out':
      return (
        <div className="flex items-center">
          <img
            src={logoutIcon}
            alt="Logout Icon"
            className="w-5 h-5 mr-2 align-middle"
          />
          <span className="font-regular text-neutral-500 whitespace-nowrap">
            {subItem.label}
          </span>
        </div>
      );

    case 'View Username':
      return (
        <div className="flex items-center">
          <span className="pl-3 font-bold truncate-28ch">
            Hi {userProfile?.nameDetails?.displayName || 'User'}
          </span>
        </div>
      );

    case 'Profile':
      return (
        <div className="flex items-center">
          <img
            src={profileOutline}
            alt="Profile Icon"
            className="w-5 h-5 mr-2 align-middle"
          />
          <span className="font-regular text-neutral-500 whitespace-nowrap">
            {subItem.label}
          </span>
        </div>
      );

    default:
      return (
        <span className="pl-3 font-bold truncate-28ch">{subItem.label}</span>
      );
  }
};
