import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  ProfileServiceClient,
  ReferenceInfoRequest,
  ReferenceInfoResponse,
  RegionDetailsInfo,
} from '@common/services/api/profileServices/_profileService';
import { RootState } from '@common/store/store';
import COMMON_PROPERTIES from '@common/properties/common-properties';

const baseAPIUrl = COMMON_PROPERTIES.baseAPIUrl;
const subscriptionKey = COMMON_PROPERTIES.subscriptionKey || '';
const client = new ProfileServiceClient(baseAPIUrl + '/profileservice');

export const regionsApi = createApi({
  reducerPath: 'regionsApi',
  baseQuery: fetchBaseQuery(),
  endpoints: (builder) => ({
    getRegions: builder.query<RegionDetailsInfo[], void>({
      queryFn: async (
        _,
        { getState }
      ): Promise<{ data?: RegionDetailsInfo[]; error?: any }> => {
        const state = getState() as RootState;
        const token: string = state.authentication.accessToken || '';

        if (!token) {
          return { error: { status: 401, data: 'No token available' } };
        }

        try {
          const availableRegions = await client.getAvailableRegions(
            token,
            subscriptionKey
          );
          return { data: availableRegions.map((region) => region) };
        } catch (error: any) {
          return {
            error: {
              status: error.response?.status || 500,
              data: error.response?.data || 'Unknown error occurred',
            },
          };
        }
      },
    }),

    getRegionsInfo: builder.query<
      ReferenceInfoResponse,
      ReferenceInfoRequest | null
    >({
      queryFn: async (
        body,
        { getState }
      ): Promise<{ data?: ReferenceInfoResponse; error?: any }> => {
        const state = getState() as RootState;
        const token: string = state.authentication.accessToken || '';

        if (!token) {
          return { error: { status: 401, data: 'No token available' } };
        }

        try {
          const regionsInfo = await client.getRegionsInfo(
            body,
            token,
            subscriptionKey
          );
          return { data: regionsInfo };
        } catch (error: any) {
          return {
            error: {
              status: error.response?.status || 500,
              data: error.response?.data || 'Unknown error occurred',
            },
          };
        }
      },
    }),
  }),
});

export const { useGetRegionsQuery, useGetRegionsInfoQuery } = regionsApi;
