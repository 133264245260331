import React, { useRef, useEffect, useState } from 'react';

interface TruncatedTextProps {
  items: Array<{ refId?: string; refText?: string }>;
  className?: string;
}

const TruncatedText: React.FC<TruncatedTextProps> = ({ items, className }) => {
  const textRef = useRef<HTMLParagraphElement>(null);
  const [displayText, setDisplayText] = useState('');

  const breakpoints = [
    { minWidth: 1536, height: 48 }, // 2xl
    { minWidth: 1280, height: 48 }, // xl
    { minWidth: 1024, height: 48 }, // lg
    { minWidth: 768, height: 48 }, // md
    { minWidth: 640, height: 48 }, // sm
    { minWidth: 0, height: 48 }, // Default
  ];

  const getContainerHeight = () => {
    const width = window.innerWidth;
    for (const breakpoint of breakpoints) {
      if (width >= breakpoint.minWidth) return breakpoint.height;
    }
    return 48; // Default height
  };

  useEffect(() => {
    const validItems = items.filter((item) => item.refId && item.refText); // Ensure valid items
    const fullText = validItems.map((item) => item.refText).join(', ');
    const containerHeight = getContainerHeight();

    const measureAndTruncate = () => {
      if (!textRef.current) return;

      let truncatedText = '';
      let remainingCount = 0;

      // Temporarily set the full text to check if it fits
      textRef.current.textContent = fullText;
      const { scrollHeight } = textRef.current;

      if (scrollHeight > containerHeight) {
        for (let i = 0; i < validItems.length; i++) {
          const tempText = truncatedText
            ? `${truncatedText}, ${validItems[i].refText}`
            : validItems[i].refText;

          textRef.current.textContent = `${tempText}, +${
            validItems.length - i
          } more`;

          if (textRef.current.scrollHeight > containerHeight) {
            remainingCount = validItems.length - i;
            break;
          }

          truncatedText = tempText ?? '';
        }

        // Set the final text with `+N more` if there are remaining items
        if (remainingCount > 0) {
          setDisplayText(`${truncatedText}, +${remainingCount} more`);
        } else {
          setDisplayText(truncatedText);
        }
      } else {
        setDisplayText(fullText);
      }
    };

    measureAndTruncate();

    // Recalculate truncation on window resize
    const handleResize = () => measureAndTruncate();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [items]);

  return (
    <p
      ref={textRef}
      className={`${className} overflow-hidden`}
      style={{
        lineHeight: '1.5em',
        height: `${getContainerHeight()}px`,
        whiteSpace: 'normal',
      }}
    >
      {displayText}
    </p>
  );
};

export default TruncatedText;
