const LatestVideosAndPodcastsSkeletons: React.FC = () => {
  const skeletonItems = Array.from({ length: 4 });

  return (
    <div className="relative mt-16 md:mb-16 lg:mb-[20rem]">
      {/* Main Content Container */}
      <div className="container mx-auto px-4 sm:px-6 md:px-8 lg:px-4 relative z-10">
        {/* Header Section */}
        <div className="flex justify-between items-center mb-8">
          <div className="bg-secondaryTeal-100 bg-shimmer bg-cover bg-no-repeat h-8 w-56 sm:w-80 rounded animate-shimmer"></div>
          <div className="hidden lg:block bg-secondaryTeal-100 bg-shimmer bg-cover bg-no-repeat h-10 w-40 rounded animate-shimmer"></div>
        </div>

        {/* Content Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          {skeletonItems.map((_, index) => (
            <div
              key={index}
              className="bg-secondaryTeal-100 relative flex bg-shimmer bg-cover bg-no-repeat rounded-lg shadow-lg overflow-hidden h-[150px] animate-shimmer"
            >
              <div className="flex-grow p-4">
                <div className="bg-cover bg-no-repeat h-4 w-1/2 rounded mb-2 "></div>
                <div className="bg-cover bg-no-repeat w-full rounded mb-2"></div>
                <div className="bg-cover bg-no-repeat h-6 w-2/3 rounded "></div>
              </div>
            </div>
          ))}
        </div>

        {/* Mobile View Button */}
        <div className="flex justify-center lg:hidden">
          <div className="px-6 py-3 bg-secondaryTeal-100 bg-shimmer bg-cover bg-no-repeat text-white font-bold rounded-md text-base leading-6 h-10 w-full animate-shimmer"></div>
        </div>
      </div>
    </div>
  );
};

export default LatestVideosAndPodcastsSkeletons;
