import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import NavbarWithMenu from '../../components/navbarWithMenu';
import Container from '../../components/container';
import searchLogo from '../../common/assets/images/mygrdc/searchMenuGray.svg';
import closeIcon from '../../common/assets/images/mygrdc/close-filled.svg';
import Footer from './footer';
import searchLandingImage from '../../common/assets/images/mygrdc/searchLandingPage.jpg';
import podcastIcon from '../../common/assets/images/mygrdc/podcast.svg';
import notFoundIcon from '../../common/assets/images/mygrdc/notFound.svg';

import Pagination from '../../components/pagination';
import {
  formatDateOrEventRange,
  formatHighlightedText,
  validateSearchInput,
} from '../../utils/helpers';
import { useSearchContentQuery } from '@common/services/api/searchServices/searchContent';
import { useLocation } from 'react-router-dom';
import SearchResultsSkeletons from '@/skeletons/searchResultsSkeletons';
import {
  DateRange,
  DateRangeOption,
  SearchResult_contentItem,
} from '@common/services/api/searchServices/_contentSearchService';
import SortingDropdown from '@components/sortingDropdown';
import ErrorMessage from '@components/errorMessage';
import FilterSidebar from '@components/filterSidebar';
import { useGetAvailableFacetsQuery } from '@common/services/api/searchServices/facets';
import FilterSidebarSkeleton from '@/skeletons/filterSideBarSkeletons';

const Search = () => {
  const [showResults, setShowResults] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [queryText, setQueryText] = useState(''); // State for actual query submission
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedOption, setSelectedOption] = useState('Relevance');
  const [shouldFetch, setShouldFetch] = useState(false);
  const [filteredFacets, setFilteredFacets] = useState<{
    [key: string]: string[];
  }>({});

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [itemsPerPage] = useState<number>(10);
  const [resetAccordion, setResetAccordion] = useState(false);

  const [dateRange, setDateRange] = useState<DateRange>({ dateRangeOption: 0 }); //By default all dates

  const [disablePersonalization, setDisablePersonalization] = useState(false);

  const location = useLocation();
  const dashboardQueryParams = new URLSearchParams(location.search);
  const dashboardQueryText = dashboardQueryParams.get('query') || '';

  const resultsRef = useRef<HTMLDivElement>(null);

  const sortingOptions = [
    { label: 'Relevance', value: 0 },
    { label: 'Newest to oldest', value: 2 },
    { label: 'Oldest to newest', value: 1 },
  ];

  const currentSortOption = useMemo(() => {
    return sortingOptions.find((option) => option.label === selectedOption)
      ?.value;
  }, [sortingOptions, selectedOption]);

  const handlePersonalisationChange = (isDisabled: boolean) => {
    setDisablePersonalization(!isDisabled);
  };

  const {
    data: searchResultsData,
    isFetching,
    error,
  } = useSearchContentQuery(
    {
      query: queryText,
      sortBy: currentSortOption,
      pageNumber: currentPage + 1,
      pageSize: itemsPerPage,
      filteredFacets: filteredFacets,
      includeFacetsInSearchResults: true,
      dateRange: dateRange,
      disablePersonalization: disablePersonalization,
    },
    {
      skip: !shouldFetch,
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: facetsResultData, isFetching: isFetchingFilterSidebar } =
    useGetAvailableFacetsQuery(
      {},
      {
        skip: !shouldFetch,
      }
    );

  useEffect(() => {
    if (dashboardQueryText) {
      setShowResults(true);
      setCurrentPage(0);
      setSearchText(dashboardQueryText);
      setQueryText(dashboardQueryText);
      setShouldFetch(true);

      const newUrl = '/search';
      window.history.replaceState(null, '', newUrl);
    }
  }, [dashboardQueryText]);

  const handleClearSearch = () => {
    setSearchText('');
    setErrorMessage('');
    setShowResults(false);
    setShouldFetch(false);
    setQueryText('');
    setCurrentPage(0);
  };

  const handleSubmitSearch = () => {
    setShowResults(true);
    setCurrentPage(0);
    setQueryText(searchText);
    setShouldFetch(true);
    handleClearAll();
  };

  const handleSearchInputChange = (e: any) => {
    const text = e.target.value;
    setSearchText(text);
    const validationError = validateSearchInput(text);
    setErrorMessage(validationError);
  };

  const handleOptionSelect = (option: any) => {
    setSelectedOption(option.label);
    setShouldFetch(true);
    setShowResults(true);
  };

  const handlePageClick = (selectedPage: number) => {
    if (selectedPage !== currentPage) {
      setCurrentPage(selectedPage);
      setShouldFetch(true);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && searchText.trim() && !errorMessage) {
      handleSubmitSearch();
    }
  };

  // Handle filter changes
  const handleFilterChange = useCallback(
    (updatedFacets: { [key: string]: string[] }) => {
      setFilteredFacets(updatedFacets);
      setCurrentPage(0); // Reset to the first page when filters change

      // Scroll to the top of the search results section
      if (resultsRef.current) {
        resultsRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    },
    []
  );

  const handleDateRangeChange = useCallback(
    (newDateRange: DateRange) => {
      setDateRange(newDateRange);
      setCurrentPage(0);

      if (newDateRange.dateRangeOption !== DateRangeOption._4) {
        // Scroll to the top of the search results section
        if (resultsRef.current) {
          resultsRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      }
    },
    [dateRange]
  );

  const hasAppliedFilters =
    Object.keys(filteredFacets).some(
      (key) => filteredFacets[key]?.length > 0
    ) || dateRange.dateRangeOption !== 0;

  const currentItems = searchResultsData?.items ?? [];

  const pageCount = Math.ceil(
    (searchResultsData?.totalCount || 0) / itemsPerPage
  );

  const handleClearAll = () => {
    setFilteredFacets({}); // Clear all filters
    setDateRange({ dateRangeOption: 0 }); //reset date range to default dates "All dates"
    setCurrentPage(0); // Reset to the first page
    // Close all filter sections
    setResetAccordion(true);
    setTimeout(() => setResetAccordion(false), 0); // Reset the flag immediately

    // Scroll to the top of the search results section
    if (resultsRef.current) {
      resultsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Container navbar={<NavbarWithMenu />}>
        <div
          className={`flex flex-col ${
            !showResults ? 'flex-auto bg-primaryTeal-100' : ''
          }`}
        >
          <div className="relative flex flex-col h-80">
            <SearchBanner showResults={showResults}></SearchBanner>

            <SearchInput
              searchText={searchText}
              errorMessage={errorMessage}
              onSearchInputChange={handleSearchInputChange}
              onClearSearch={handleClearSearch}
              onSearchSubmit={handleSubmitSearch}
              onKeyDown={handleKeyDown}
              showResults={showResults}
            />
            {errorMessage && (
              <div className="w-[345px] sm:w-[550px] md:w-2/3 lg:w-2/3 mx-auto mt-4 sm:px-0 z-20">
                <div
                  className={`flex items-center text-semanticsError-500 font-regular text-sm  z-20
                              ${searchText ? 'mb-32' : 'mb-20 md:mb-14'}
                           bg-red-100 px-4 py-2 rounded-lg w-[345px] sm:w-full min-h-14 max-w-lg`}
                >
                  <ErrorMessage message={errorMessage} />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="flex w-full mx-auto">
          {/* Filter Sidebar */}
          {showResults && (
            <div className="hidden lg:block w-96 p-4 sticky top-0 bottom-12 self-start ml-8 xl:ml-48">
              {isFetchingFilterSidebar ? (
                <FilterSidebarSkeleton />
              ) : (
                <FilterSidebar
                  facets={facetsResultData?.facets?.items || {}}
                  onFilterChange={handleFilterChange}
                  filteredFacets={filteredFacets}
                  resetAccordion={resetAccordion} // Pass resetAccordion flag
                  onDateRangeChange={handleDateRangeChange}
                  dateRange={{
                    ...dateRange,
                    dateRangeOption: dateRange.dateRangeOption ?? 0, // Ensure dateRangeOption is not undefined
                  }}
                  onPersonalisationChange={handlePersonalisationChange}
                  disablePersonalization={disablePersonalization}
                />
              )}
            </div>
          )}

          <div className="w-full lg:w-4/5 p-4 lg:mr-16">
            {showResults && (
              <>
                {isFetching ? (
                  <SearchResultsSkeletons />
                ) : error ? (
                  // Error handling: show 'No Results Found' structure with results count 0
                  <div className="flex w-full mx-auto">
                    <div className="w-full bg-shades-white flex flex-col px-4 sm:px-6">
                      <div
                        ref={resultsRef}
                        className="flex justify-between items-center mb-4 -mt-16"
                      >
                        <SearchResultsHeading totalCount={0} />
                        <SortingDropdown
                          selectedOption={selectedOption}
                          sortingOptions={sortingOptions}
                          onSelectOption={handleOptionSelect}
                        />
                      </div>
                      <SearchResultsSummary
                        totalCount={0}
                        queryText={queryText}
                        searchText={searchText}
                        hasAppliedFilters={hasAppliedFilters}
                        onClearAll={handleClearAll}
                      />
                      <div className="flex-1">
                        <NoResultsFound />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex w-full mx-auto">
                    {/* Search Results Section */}
                    <div className="w-full bg-shades-white flex flex-col px-4 sm:px-6">
                      <div
                        ref={resultsRef}
                        className="flex justify-between items-center mb-4 -mt-16"
                      >
                        <SearchResultsHeading
                          totalCount={searchResultsData?.totalCount ?? 0}
                        />
                        <SortingDropdown
                          selectedOption={selectedOption}
                          sortingOptions={sortingOptions}
                          onSelectOption={handleOptionSelect}
                        />
                      </div>
                      <SearchResultsSummary
                        totalCount={searchResultsData?.totalCount ?? 0}
                        queryText={queryText}
                        searchText={searchText}
                        hasAppliedFilters={hasAppliedFilters}
                        onClearAll={handleClearAll}
                      />
                      <div className="flex-1">
                        <SearchResults items={currentItems} />
                      </div>

                      {currentItems.length === 0 && !isFetching && (
                        <NoResultsFound />
                      )}

                      {pageCount > 1 && (
                        <Pagination
                          currentPage={currentPage}
                          pageCount={pageCount}
                          onPageChange={handlePageClick}
                        />
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <Footer />
      </Container>
    </div>
  );
};

export default Search;

interface SearchInputProps {
  searchText: string;
  errorMessage: string;
  onSearchInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClearSearch: () => void;
  onSearchSubmit: () => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  showResults: boolean;
}

const SearchInput: React.FC<SearchInputProps> = ({
  searchText,
  errorMessage,
  onSearchInputChange,
  onClearSearch,
  onSearchSubmit,
  onKeyDown,
  showResults,
}) => (
  <div
    className={`flex items-center bg-shades-white rounded-xl shadow-bottom
            px-0 sm:px-4 py-3 h-16 md:h-20 relative transition duration-300 ease-in-out focus-within:border-tint-dark
            border-transparent border-2 box-border font-regular w-[345px] sm:w-[550px] md:w-2/3 lg:w-2/3 mx-auto my-auto mt-24 min-h-16 sm:min-h-20 ${
              !showResults ? 'md:mt-44' : 'md:mt-16'
            }`}
  >
    <div className="relative flex items-center flex-grow h-14 md:h-full bg-shades-white rounded-md">
      <span className="absolute inset-y-0 left-0 items-center pl-2 md:pl-0 sm:pl-2 hidden sm:flex">
        <img src={searchLogo} alt="Search" width={24} height={24} />
      </span>
      <input
        type="text"
        placeholder="Search for resources, trials, events & more"
        value={searchText}
        onChange={onSearchInputChange}
        onKeyDown={onKeyDown}
        className="w-full pl-2 sm:pl-8 h-full rounded-md placeholder-neutral-600 outline-none
          placeholder:text-[16px] placeholder:leading-6 placeholder:text-left placeholder:font-regular"
      />
      {searchText && (
        <button
          className="absolute right-4 top-1/2 transform -translate-y-1/2 p-1"
          onClick={onClearSearch}
        >
          <img src={closeIcon} alt="Clear" />
        </button>
      )}
    </div>

    <button
      onClick={onSearchSubmit}
      disabled={!searchText.trim() || !!errorMessage}
      className={`hidden sm:block h-full px-4 text-shades-white rounded-md bg-device-green hover:bg-primary-400
                  ${
                    searchText
                      ? 'sm:w-20 md:w-24 lg:w-28'
                      : 'sm:w-24 md:w-31 lg:w-44'
                  }`}
    >
      Search
    </button>
  </div>
);

const NoResultsFound: React.FC = () => (
  <div className="flex flex-col items-center text-center p-8">
    <div className="flex items-center justify-center mb-4 rounded-full bg-primaryTeal-100 w-32 h-32">
      <img src={notFoundIcon} alt="No results found" className="w-16 h-16" />
    </div>

    <p className="text-gray-500 mt-4 max-w-96 leading-relaxed font-regular">
      Sorry we couldn’t find anything that matches your search. Try broadening
      your search phrase, rewording your search or clearing any filters.
    </p>
  </div>
);

interface SearchResultsProps {
  items: SearchResult_contentItem[];
}

const SearchResults: React.FC<SearchResultsProps> = ({ items }) => (
  <div id="search-results" className="space-y-6">
    {items.map((result, index) => (
      <div
        key={result?.document?.id || index}
        className={`pb-4 flex items-start space-x-4 ${
          index !== items.length - 1 ? 'border-b' : ''
        }`}
      >
        {/* Thumbnail Section */}
        {result?.document?.images?.thumbnail &&
          result?.document?.duration &&
          result?.document?.type === 'podcast' && (
            <div className="relative w-20 h-20 flex-shrink-0 sm:w-20 sm:h-20">
              <img
                src={result?.document?.images?.thumbnail}
                alt={result?.document?.title}
                className="w-full h-full object-cover rounded-md"
              />
              <img
                src={podcastIcon}
                alt="Podcast icon"
                className="absolute bottom-2 left-2 h-7 w-7 rounded-full"
              />
            </div>
          )}

        {/* Content Section */}
        <div className="flex-1 w-36">
          <p className="text-sm font-regular truncate">
            {formatDateOrEventRange(result?.document)}
          </p>
          <h3 className="text-lg font-semiBold mt-1 overflow-hidden">
            <a
              href={result?.document?.url}
              target="_blank"
              rel="noopener noreferrer"
              className="hover:underline"
            >
              {result?.highlights?.title?.[0]
                ? formatHighlightedText(result.highlights.title[0])
                : result?.document?.title}
            </a>
          </h3>
          <p className="font-regular text-neutral-600 overflow-hidden text-ellipsis line-clamp-2">
            {result?.document?.summary}
          </p>
        </div>
      </div>
    ))}
  </div>
);

interface SearchResultsHeadingProps {
  totalCount: number;
}

const SearchResultsHeading: React.FC<SearchResultsHeadingProps> = ({
  totalCount,
}) => (
  <h2 className="text-2xl font-bold">
    {totalCount > 0 ? `${totalCount} results` : 'No results found'}
  </h2>
);

interface SearchResultsSummaryProps {
  totalCount: number;
  queryText: string;
  searchText: string;
  hasAppliedFilters: boolean;
  onClearAll: () => void;
}

const SearchResultsSummary: React.FC<SearchResultsSummaryProps> = ({
  totalCount,
  queryText,
  searchText,
  hasAppliedFilters,
  onClearAll,
}) => (
  <>
    {' '}
    <div className="font-regular mb-6 bg-neutral-100 text-center py-2 rounded">
      {totalCount > 0 ? (
        <>
          Showing results for{' '}
          <span className="font-boldItalic">{queryText}</span>
          {hasAppliedFilters && <span> with currently applied filters</span>}
        </>
      ) : (
        <>
          0 search results for{' '}
          <span className="font-boldItalic">{searchText}</span>
        </>
      )}
    </div>
    {hasAppliedFilters && (
      <button
        className={
          'underline text-sm font-regular400 mb-1 ml-auto text-secondary-600'
        }
        onClick={onClearAll}
      >
        Clear all
      </button>
    )}
  </>
);

interface SearchBannerProps {
  showResults: boolean;
}

const SearchBanner: React.FC<SearchBannerProps> = ({ showResults }) => (
  <>
    <div className="bg-tint-light h-4/5 md:h-2/3 top-0 absolute w-full">
      <div className="w-[345px] sm:w-[550px] md:w-2/3 lg:w-2/3 mx-auto sm:px-0 mt-8 md:mt-20">
        {!showResults && (
          <div className="font-bold text-4xl leading-9 text-shades-white tracking-wide text-center sm:text-left md:text-5xl md:leading-10">
            Search
          </div>
        )}
      </div>
    </div>
    {/* Background Image */}
    {!showResults && (
      <div className="absolute md:right-0 w-full bottom-0 md:w-1/2 h-1/2 md:h-full">
        <img
          src={searchLandingImage}
          alt="Background"
          className="h-full object-cover w-full rounded-bl-[80px] md:rounded-bl-[150px]"
        />
      </div>
    )}
  </>
);
