import { UserProfileInfo } from '@common/services/api/profileServices/_profileService';
import chevronLeft from '../../common/assets/images/mygrdc/chevron-left.svg';

interface PersonalDetailsProps {
  onBackClick: () => void;
  userProfile?: UserProfileInfo;
}

const PersonalDetails: React.FC<PersonalDetailsProps> = ({
  onBackClick,
  userProfile,
}) => {
  return (
    <div id="personal-details-page">
      <button
        id="back-to-profile-button"
        className=" mb-6 flex items-center"
        onClick={onBackClick}
      >
        <img
          id="back-chevron-icon"
          src={chevronLeft}
          alt="Chevron Right"
          className="w-5 h-5"
        />
        <p
          id="back-to-profile-text"
          className="text-primary-500 font-bold ml-2"
        >
          Back to profile
        </p>
      </button>

      <h1 id="personal-detail-title" className="text-2xl font-bold mb-6 mr-4">
        Personal details
      </h1>
      <form id="personal-details-form" className="space-y-6">
        <div id="first-name-field">
          <label id="first-name-label" className="block font-bold mb-2">
            First Name
          </label>
          <input
            id="first-name-input"
            type="text"
            defaultValue={userProfile?.nameDetails?.firstName || ''}
            className="block w-full border border-shades-blackBorder rounded-md p-2 shadow-inner max-w-6xl h-14 font-regular text-neutral-800"
          />
        </div>

        <div id="last-name-field">
          <label id="last-name-label" className="block font-bold mb-2">
            Last Name
          </label>
          <input
            id="last-name-input"
            type="text"
            defaultValue={userProfile?.nameDetails?.lastName || ''}
            className="block w-full border border-shades-blackBorder rounded-md p-2 shadow-inner max-w-6xl h-14 font-regular text-neutral-800"
          />
        </div>

        <div id="display-name-field">
          <label id="display-name-label" className="block font-bold mb-2">
            Display Name
          </label>
          <input
            id="display-name-input"
            type="text"
            defaultValue={userProfile?.nameDetails?.displayName || ''}
            className="block w-full border border-shades-blackBorder rounded-md p-2 shadow-inner max-w-6xl h-14 font-regular text-neutral-800"
          />
          <p
            id="display-name-description"
            className="font-regular text-neutral-800 mt-4  max-w-6xl"
          >
            This name is displayed throughout MyGRDC and may be visible to
            others. You can use your real name, a nickname, or something more
            anonymous.
          </p>
        </div>

        <div
          id="save-changes-section"
          className="flex justify-end max-w-6xl pt-2"
        >
          <button
            id="save-changes-button"
            type="submit"
            className="bg-primary-400 text-white font-medium py-3 px-8 rounded-lg focus:outline-none"
          >
            Save changes
          </button>
        </div>
      </form>
    </div>
  );
};
export default PersonalDetails;
