import React from 'react';

interface ImageBannerProps {
  imageSrc: string;
  altText: string;
}

const ImageBanner: React.FC<ImageBannerProps> = ({ imageSrc, altText }) => (
  <div
    id="image-banner"
    className="relative w-[93%] h-72 rounded-br-[160px] overflow-hidden"
  >
    <img src={imageSrc} alt={altText} className="w-full h-full object-cover" />
  </div>
);

export default ImageBanner;
