import { SearchResponseBasic } from '@common/services/api/searchServices/_contentSearchService';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { set } from 'date-fns';

export interface ISearchState {
  searchText: string;
  isPersonalisedSearch: boolean;
  baseFacets: SearchResponseBasic | undefined;
  selectedFacets: Record<string, string[]> | undefined;
  facetsFromResult?: string[];
}

export const initialState: ISearchState = {
  searchText: '',
  isPersonalisedSearch: true,
  baseFacets: undefined,
  selectedFacets: undefined,
  facetsFromResult: undefined,
};

const searchSlice = createSlice({
  name: 'search',
  initialState: initialState,
  reducers: {
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
    setIsPersonalisedSearch: (state, action: PayloadAction<boolean>) => {
      state.isPersonalisedSearch = action.payload;
    },
    setBaseFacets: (state, action) => {
      state.baseFacets = action.payload;
    },
    setSelectedFacets: (state, action: PayloadAction<Record<string, string[]>>) => {
      state.selectedFacets = action.payload;
    },
    setFacetsFromResult: (state, action: PayloadAction<string[]>) => {
      state.facetsFromResult = action.payload;
    },
  },
});

export const { setSearchText, setIsPersonalisedSearch, setBaseFacets, setSelectedFacets, setFacetsFromResult } =
  searchSlice.actions;
export default searchSlice.reducer;

// Selectors
export const getSearchText = (state: { search: ISearchState }) => state.search.searchText;
export const getIsPersonalisedSearch = (state: { search: ISearchState }) => state.search.isPersonalisedSearch;
export const getBaseFacets = (state: { search: ISearchState }) => state.search.baseFacets;
export const getSelectedFacets = (state: { search: ISearchState }) => state.search.selectedFacets;
export const getFacetsFromResult = (state: { search: ISearchState }) => state.search.facetsFromResult;
