import React from 'react';
import rightWhite from '../../common/assets/images/mygrdc/chevron-right-white.svg';

interface TileProps {
  title: string;
  imageSrc?: string;
}

const CurvedTile: React.FC<TileProps> = ({ title, imageSrc }) => {
  return (
    <div className="relative rounded-lg overflow-hidden w-full h-full">
      {/* Conditional Background for top curved portion */}
      <div
        className={`absolute top-0 left-0 w-full h-full ${
          imageSrc ? '' : 'bg-primary-400'
        }`}
        style={{
          backgroundImage: imageSrc ? `url(${imageSrc})` : 'none',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />

      {/* Solid overlay at the bottom with curved top-right corner */}
      <div className="absolute bottom-0 bg-primary-300 w-full h-2/3 rounded-tr-[60rem] z-10 p-6 flex flex-col justify-end">
        <h2 className="text-4xl font-bold leading-9 text-white w-full max-w-3/5 break-words">
          {title}
        </h2>

        <a
          href="https://grdc.com.au/resources-and-publications/all-publications/publications/2023/water-smart-farming-manual"
          target="_blank"
          rel="noopener noreferrer"
          className="text-white font-bold mt-4 flex items-center space-x-1"
        >
          <span>Read more</span>
          <img src={rightWhite} alt="Chevron Right" className="w-6 h-6" />
        </a>
      </div>
    </div>
  );
};

const SharpTile: React.FC<TileProps> = ({ title, imageSrc }) => {
  return (
    <div className="relative flex flex-col justify-end w-full h-full rounded-lg bg-primary-200 overflow-hidden">
      {/* Left side with the image or background color */}
      <div
        className={`absolute top-0 left-0 w-1/3 h-full ${
          imageSrc ? 'bg-cover bg-center' : 'bg-primary-400'
        }`}
        style={imageSrc ? { backgroundImage: `url(${imageSrc})` } : {}}
      ></div>

      {/* Right side with content */}
      <div className="relative z-10 flex flex-col justify-end p-6 w-2/3 ml-auto h-full">
        <h2 className="text-xl leading-6 font-bold text-white mt-4 w-full break-words whitespace-normal">
          {title}
        </h2>
        <a
          href="https://grdc.com.au/resources-and-publications/all-publications/publications/2023/soil-behaviour-of-pre-emergent-herbicides"
          target="_blank"
          rel="noopener noreferrer"
          className="text-white font-bold mt-4 flex items-center space-x-1"
        >
          <span>Read more</span>
          <img src={rightWhite} alt="Chevron Right" className="w-6 h-6" />
        </a>
      </div>
    </div>
  );
};

const CurvedBottomLeftTile: React.FC<TileProps> = ({ title, imageSrc }) => {
  return (
    <div className="relative p-6 flex flex-col justify-end w-full h-full rounded-[8px] bg-primary-400 overflow-hidden">
      {/* Inner div showing image if present, otherwise bg-primary-200 */}
      <div
        className={`absolute top-0 right-0 w-2/3 h-4/5 rounded-bl-[200px] 
          ${imageSrc ? 'bg-cover bg-center' : 'bg-primary-200'}`}
        style={imageSrc ? { backgroundImage: `url(${imageSrc})` } : {}}
      ></div>

      {/* Content */}
      <div className="relative z-10">
        <h2 className="text-xl leading-6 font-bold text-white mt-4 w-full break-words whitespace-normal lg:max-w-[50%]">
          {title}
        </h2>
        <a
          href="https://grdc.com.au/resources-and-publications/all-publications/publications/2024/groundcover-paddock-diary"
          target="_blank"
          rel="noopener noreferrer"
          className="text-white font-bold mt-4 flex items-center space-x-1"
        >
          <span>Read more</span>
          <img src={rightWhite} alt="Chevron Right" className="w-6 h-6" />
        </a>
      </div>
    </div>
  );
};

const TrendingTopics: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8 mb-12">
      <div className="flex flex-col sm:flex-row justify-between mb-6">
        <h2 className="font-bold text-3xl leading-tight text-left">
          Trending topics
        </h2>
        {/* On large screens, button remains beside the heading */}
        {/* <button className="hidden sm:block bg-primary-400 text-white text-lg font-bold rounded-md hover:bg-primary-500 transition-all py-4 px-4 rounded-lg w-auto">
          See all trending topics
        </button> */}
      </div>

      {/* Flexbox container to ensure equal heights */}
      <div className="flex flex-col sm:flex-row gap-4">
        {/* CurvedTile with flex-grow */}
        <div className="flex-grow sm:h-auto h-[441px]">
          <CurvedTile
            title="Water-Smart Farming Manual"
            // imageSrc="https://picsum.photos/703/416"
          />
        </div>

        {/* SharpTile and CurvedBottomLeftTile stacked */}
        <div className="flex flex-col gap-4 sm:w-1/2">
          <div className="flex-grow h-[220px]">
            <SharpTile
              title="Soil behaviour of pre-emergent herbicides in Australian farming systems"
              // imageSrc="https://picsum.photos/700/412"
            />
          </div>
          <div className="flex-grow h-[220px]">
            <CurvedBottomLeftTile
              title="GroundCover Paddock Diary"
              // imageSrc="https://picsum.photos/701/412"
            />
          </div>
        </div>
      </div>

      {/* On small screens, button moves below the tiles */}
      {/* <div className="block sm:hidden mt-6">
        <button className="bg-primary-400 text-white text-base font-bold py-4 px-4 rounded-lg w-full">
          See all trending topics
        </button>
      </div> */}
    </div>
  );
};

export default TrendingTopics;
