import errorIcon from '../common/assets/images/mygrdc/error.svg';

interface ErrorMessageProps {
  message: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message }) => (
  <>
    <img
      src={errorIcon}
      alt="Error icon"
      className="-mt-4 lg:-mt-3 lg:mb-1 mr-2 h-4 w-4 rounded-full"
    />
    <span className="text-left">{message}</span>
  </>
);

export default ErrorMessage;
