import { UserProfileInfo } from '@common/services/api/profileServices/_profileService';
import { createSlice } from '@reduxjs/toolkit';

export interface IAuthenticationState {
  accessToken: string | null;
  idToken: string | null;
  refreshToken: string | null;
  userProfile: UserProfileInfo | undefined;
}

export const initialState: IAuthenticationState = {
  accessToken: null,
  idToken: null,
  refreshToken: null,
  userProfile: undefined,
};

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: initialState,
  reducers: {
    setToken: (state, action) => {
      state.accessToken = action.payload.token;
      state.idToken = action.payload.tokenExpires;
      state.refreshToken = action.payload.objectId;
    },
    removeToken: (state) => {
      state.accessToken = null;
      state.idToken = null;
      state.refreshToken = null;
    },
    setUserProfile: (state, action) => {
      state.userProfile = action.payload;
    },
  },
});

export const { setToken, removeToken, setUserProfile } = authenticationSlice.actions;
export default authenticationSlice.reducer;

// Selectors
export const selectAccessToken = (state: { authentication: IAuthenticationState }) => state.authentication.accessToken;
export const selectIdToken = (state: { authentication: IAuthenticationState }) => state.authentication.idToken;
export const selectRefreshToken = (state: { authentication: IAuthenticationState }) =>
  state.authentication.refreshToken;
export const selectUserProfile = (state: { authentication: IAuthenticationState }) => state.authentication.userProfile;
