import { ContentItem } from '@common/services/api/searchServices/_contentSearchService';
import { format, isSameDay, isSameMonth, isSameYear } from 'date-fns';

export const formatDatePublished = (date: Date | undefined): string => {
  return date ? format(new Date(date), 'dd MMM yyyy') : '';
};

export const formatDate = (date: string | undefined): string => {
  if (!date) return '';
  const parsedDate = new Date(date);
  return format(parsedDate, 'dd/MM/yyyy');
};

export const formatDateForInput = (date: string | undefined): string => {
  if (!date) return '';
  const parsedDate = new Date(date);
  const localDate = new Date(
    parsedDate.getTime() - parsedDate.getTimezoneOffset() * 60000
  );
  const dateString = localDate.toISOString().split('T')[0];
  return dateString;
};

export const joinRegions = (regions?: string[]): string => {
  return regions && regions.length > 0 ? regions.join(', ') : '';
};

export const formatHighlightedText = (
  text?: string | undefined
): JSX.Element[] => {
  if (!text) return [];

  return text
    .split(/(<strong>|<\/strong>)/g)
    .map((part, index) => {
      if (part === '<strong>' || part === '</strong>') {
        return null;
      }
      const isStrong = text.includes(`<strong>${part}</strong>`);
      return (
        <span
          key={index}
          style={{
            color: isStrong ? 'green' : 'inherit',
            fontWeight: isStrong ? 'bold' : 'normal',
          }}
        >
          {part}
        </span>
      );
    })
    .filter((element): element is JSX.Element => element !== null);
};

export const specialCharacters = /[^a-zA-Z0-9\s]/;

export const validateSearchInput = (text: string): string => {
  if (text.length > 512) {
    return 'Search exceeds 512 characters. Please shorten your query.';
  } else if (specialCharacters.test(text)) {
    return 'Special characters are not allowed. Please remove them and try again.';
  }
  return '';
};

export const formatEventDateRange = (
  startDate: Date | undefined,
  endDate: Date | undefined
): string => {
  if (!startDate || !endDate) {
    return '';
  }

  // Format parts
  const dayFormat = 'd';
  const monthFormat = 'MMM';
  const yearFormat = 'yyyy';

  // Case 1: Same day
  if (isSameDay(startDate, endDate)) {
    return `${format(startDate, `${dayFormat} ${monthFormat} ${yearFormat}`)}`;
  }

  // Case 2: same months, different days
  if (isSameMonth(startDate, endDate) && isSameYear(startDate, endDate)) {
    return `${format(startDate, dayFormat)} - ${format(
      endDate,
      `${dayFormat} ${monthFormat} ${yearFormat}`
    )}`;
  }

  // Case 3: Same year, different months
  if (isSameYear(startDate, endDate)) {
    return `${format(startDate, `${dayFormat} ${monthFormat}`)} - ${format(
      endDate,
      `${dayFormat} ${monthFormat} ${yearFormat}`
    )}`;
  }

  // Case 4: Different years
  return `${format(
    startDate,
    `${dayFormat} ${monthFormat} ${yearFormat}`
  )} - ${format(endDate, `${dayFormat} ${monthFormat} ${yearFormat}`)}`;
};

export const formatDuration = (timeToConvert: any) => {
  const [hours, minutes, seconds] = timeToConvert.split(':').map(Number);

  let formattedDuration = '';

  if (hours > 0) {
    formattedDuration += `${hours} ${hours === 1 ? 'hr' : 'hrs'} `;
  }
  if (minutes > 0) {
    formattedDuration += `${minutes} ${minutes === 1 ? 'min' : 'mins'}  `;
  }
  if (seconds > 0) {
    formattedDuration += `${seconds} ${seconds === 1 ? 'sec' : 'secs'} `;
  }

  return formattedDuration.trim();
};

export function formatDateRegion(
  datePublished?: Date,
  region?: string[]
): string {
  const formattedDate = formatDatePublished(datePublished);
  const formattedRegion =
    region && region.length > 0 ? joinRegions(region) : '';

  if (formattedDate && formattedRegion) {
    return `${formattedDate} | ${formattedRegion}`;
  }
  return formattedDate || formattedRegion;
}

export const formatDateOrEventRange = (
  document: ContentItem | undefined
): string => {
  if (!document) return '';

  let formattedDate = '';

  if (document.type === 'event') {
    if (document.eventStartDate && document.eventEndDate) {
      formattedDate = formatEventDateRange(
        document.eventStartDate,
        document.eventEndDate
      );
    }
  }
  // If the type is not 'event', check for calculatedDate
  else if (document.calculatedDate) {
    formattedDate = formatDatePublished(document.calculatedDate);
  }

  // Format the region if it exists and is a non-empty array
  const formattedRegion =
    Array.isArray(document.region) && document.region.length > 0
      ? joinRegions(document.region)
      : '';

  // Combine formatted date and region with a ' | ' separator, filtering out empty strings
  return [formattedDate, formattedRegion].filter(Boolean).join(' | ');
};
