import React from 'react';

interface ResultsCountProps {
  startItem: number;
  endItem: number;
  totalItems: number;
  highlightText: string;
}

const ResultsCount: React.FC<ResultsCountProps> = ({
  startItem,
  endItem,
  totalItems,
  highlightText,
}) => (
  <span id="results-count" className="font-regular mx-2">
    {`${startItem} - ${endItem} of ${totalItems} for `}
    <span className="font-boldItalic">{highlightText}</span>
  </span>
);

export default ResultsCount;
