const SearchResultsSkeletons: React.FC = () => {
  const skeletonItems = Array.from({ length: 10 }); // Adjust the number of skeleton items. putting 10 as 10 results are displayed in a page

  return (
    <div className="w-full mx-auto flex flex-col px-4 sm:px-6">
      {/* Header Skeleton */}
      <div className="relative">
        <div className="flex justify-between items-center mb-4 -mt-14">
          <div className="h-8 w-2/5 sm:w-1/5 rounded bg-neutral-200 bg-greyShimmer animate-shimmer"></div>
          <div className="h-8 w-40 bg-greyShimmer rounded bg-neutral-200 animate-shimmer"></div>
        </div>
      </div>

      {/* Showing Results Skeleton */}
      <div className="font-regular mb-6 bg-neutral-200 bg-greyShimmer  text-center py-2 rounded animate-shimmer">
        <div className="h-6 w-2/3 mx-auto  rounded"></div>
      </div>

      {/* Skeleton for Items */}
      <div className="space-y-6">
        {skeletonItems.map((_, index) => (
          <div key={index} className={`pb-4 flex items-start space-x-4 `}>
            {/* Content Skeleton */}
            <div className="flex-1 w-36">
              {/* Date and Region Skeleton */}
              <div className="h-4 w-1/4 rounded bg-neutral-200 bg-greyShimmer animate-shimmer mb-2"></div>

              {/* Title Skeleton */}
              <div className="h-6 w-2/5 rounded bg-neutral-200 bg-greyShimmer animate-shimmer mb-2"></div>

              {/* Summary Skeleton */}
              <div className="h-4 w-full rounded bg-neutral-200 bg-greyShimmer animate-shimmer mb-1"></div>
              <div className="h-4 w-11/12 rounded bg-neutral-200 bg-greyShimmer animate-shimmer"></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchResultsSkeletons;
