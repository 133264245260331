import React, { useCallback, useEffect, useRef, useState } from 'react';
import chevronUp from '../common/assets/images/mygrdc/chevron-up.svg';
import chevronDown from '../common/assets/images/mygrdc/chevron-down.svg';
import checkboxOutline from '../common/assets/images/mygrdc/checkbox-outline.svg';
import checkboxFilled from '../common/assets/images/mygrdc/checkbox-filled.svg';
import radioButtonChecked from '../common/assets/images/mygrdc/radio-button-checked.svg';
import radioButtonUnchecked from '../common/assets/images/mygrdc/radio-button-unchecked.svg';
import wheat from '../common/assets/images/mygrdc/wheat.svg';
import calendar from '../common/assets/images/mygrdc/calendar.svg';
import pest from '../common/assets/images/mygrdc/pest.svg';
import document from '../common/assets/images/mygrdc/document.svg';
import event from '../common/assets/images/mygrdc/event.svg';
import soilManagement from '../common/assets/images/mygrdc/soil-management.svg';
import tractor from '../common/assets/images/mygrdc/tractor.svg';
import web from '../common/assets/images/mygrdc/web.svg';
import location from '../common/assets/images/mygrdc/location-outline.svg';
import { sub } from 'date-fns';

import {
  FacetBasevalue,
  FacetBaseResult,
  DateRangeOption,
  CustomDateRange,
} from '@common/services/api/searchServices/_contentSearchService';
import { formatDate, formatDateForInput } from '@/utils/helpers';

interface DateRange {
  dateRangeOption: DateRangeOption;
  customRange?: CustomDateRange;
}
interface FilterSidebarProps {
  facets?: { [key: string]: FacetBasevalue };
  onFilterChange: (updatedFacets: { [key: string]: string[] }) => void;
  filteredFacets: { [key: string]: string[] };
  resetAccordion: boolean;
  onDateRangeChange: (dateRange: DateRange) => void;
  dateRange: {
    dateRangeOption: DateRangeOption;
    customDateRange?: CustomDateRange;
  };
  onPersonalisationChange: (isDisabled: boolean) => void;
  disablePersonalization: boolean;
}

export const FilterSidebar: React.FC<FilterSidebarProps> = React.memo(
  ({
    facets = {},
    onFilterChange,
    filteredFacets,
    resetAccordion,
    onDateRangeChange,
    dateRange,
    onPersonalisationChange,
    disablePersonalization,
  }) => {
    const [openSection, setOpenSection] = useState<string | null>(null);

    const handleToggleChange = (isDisabled: boolean) => {
      onPersonalisationChange(isDisabled);
    };

    const memoizedOnFilterChange = useCallback(
      (formattedFacets: { [key: string]: string[] }) => {
        onFilterChange(formattedFacets);
      },
      [onFilterChange]
    );

    const memoizedOnDateRangeChange = useCallback(
      (dateRange: DateRange) => {
        if (onDateRangeChange) {
          onDateRangeChange(dateRange);
        }
      },
      [onDateRangeChange]
    );

    useEffect(() => {
      if (resetAccordion) {
        setOpenSection(null);
      }
    }, [resetAccordion]);

    const [selectedItems, setSelectedItems] = useState<{
      [key: string]: Set<string>;
    }>({});

    const handleAccordionToggle = (key: string) => {
      setOpenSection((prev) => (prev === key ? null : key));
    };

    const handleCheckboxChange = (key: string, name: string) => {
      const updatedFacets = { ...filteredFacets };

      if (!updatedFacets[key]) {
        updatedFacets[key] = [];
      }

      // Check if the item is already selected, and toggle its presence
      if (updatedFacets[key].includes(name)) {
        // Remove the item from the array
        updatedFacets[key] = updatedFacets[key].filter((item) => item !== name);
      } else {
        // Add the item to the array
        updatedFacets[key] = [...updatedFacets[key], name];
      }

      // If the array becomes empty, remove the key from the object
      if (updatedFacets[key].length === 0) {
        delete updatedFacets[key];
      }

      // Pass the updated object to the parent via the onFilterChange callback
      onFilterChange(updatedFacets);
    };

    const getSelectedCount = (key: string) => filteredFacets[key]?.length || 0;

    // Clear selected items for a section
    useEffect(() => {
      // Convert Sets to arrays and pass to parent
      const formattedFacets = Object.fromEntries(
        Object.entries(selectedItems)
          .filter(([_, set]) => set.size > 0)
          .map(([key, set]) => [key, Array.from(set)])
      );

      memoizedOnFilterChange(formattedFacets);
    }, [selectedItems]);

    const handleClear = (key: string) => {
      const updatedFacets = { ...filteredFacets };
      delete updatedFacets[key];
      onFilterChange(updatedFacets); // Notify parent about the cleared section
    };

    return (
      <div
        id="filter-sidebar-container"
        className="w-full min-h-screen flex flex-col bg-white rounded-lg -mt-[4.0rem] xl:-ml-24"
      >
        <div className="sticky top-0">
          <h2 className="text-2xl font-bold mb-4">Refine</h2>
          <PersonaliseSearch
            onToggleChange={handleToggleChange}
            isDisabled={disablePersonalization}
          />
          <div
            id="filter-sections-container"
            className="flex-1 overflow-y-auto pr-2 pt-4"
          >
            {Object.entries(facets).map(([key, section]) => (
              <div
                key={key}
                data-key={key}
                id={`filter-section-${key}`}
                className={'mb-6 border-b'}
              >
                <div
                  id={`filter-section-header-${key}`}
                  className={`flex justify-between items-center mb-2 ${
                    (section.items?.length ?? 0) === 0
                      ? 'text-gray-400 cursor-default'
                      : 'cursor-pointer'
                  }`}
                  onClick={() =>
                    section.items &&
                    section.items.length > 0 &&
                    handleAccordionToggle(key)
                  }
                >
                  <div className="flex flex-col items-start mb-1">
                    <div className="flex items-center">
                      <ImageIcon
                        keyName={key}
                        altText={section.displayName}
                        isGreyedOut={(section.items?.length ?? 0) === 0}
                      />
                      <span
                        id={`filter-section-title-${key}`}
                        className="ml-4 font-semiBold w-48"
                      >
                        {section.displayName}
                      </span>
                    </div>
                    {getSelectedCount(key) > 0 && (
                      <span
                        id={`filter-section-count-${key}`}
                        className="text-sm font-regular400 text-gray-500 mt-2 ml-9"
                      >
                        {`${getSelectedCount(key)} selected`}
                      </span>
                    )}
                  </div>

                  <img
                    id={`filter-section-toggle-${key}`}
                    src={openSection === key ? chevronUp : chevronDown}
                    alt="Toggle"
                    className={`w-4 h-4 ${
                      (section.items?.length ?? 0) === 0
                        ? 'opacity-40 cursor-default'
                        : ''
                    }`}
                  />
                </div>

                {/* Facets Items */}
                {openSection === key &&
                  section.items &&
                  section.items.length > 0 && (
                    <>
                      {getSelectedCount(key) > 0 ? (
                        <button
                          id={`filter-clear-button-${key}`}
                          onClick={() => handleClear(key)}
                          className="underline text-sm font-regular400 mb-1 ml-9 text-secondary-600"
                        >
                          Clear
                        </button>
                      ) : (
                        <span
                          id={`filter-section-count-${key}`}
                          className="text-sm font-regular400 text-gray-500 mt-2 ml-9"
                        >
                          Showing all
                        </span>
                      )}

                      <ul
                        id={`filter-items-list-${key}`}
                        className="mt-2 space-y-3 mb-2 max-h-[200px] overflow-y-auto ml-9"
                      >
                        {section.items.map((item, itemIndex) => (
                          <li
                            id={`filter-item-${key}-${itemIndex}`}
                            key={itemIndex}
                            className="flex flex-col"
                          >
                            {key === 'cropType' ? (
                              <CropSection
                                keyName={key}
                                item={item}
                                selectedItems={
                                  new Set(filteredFacets[key] || [])
                                }
                                handleCheckboxChange={handleCheckboxChange}
                              />
                            ) : (
                              <RegularSection
                                keyName={key}
                                item={item}
                                selectedItems={
                                  new Set(filteredFacets[key] || [])
                                }
                                handleCheckboxChange={handleCheckboxChange}
                              />
                            )}
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
              </div>
            ))}

            {/* Date Range Accordion Section */}
            <DateRangeFilter
              isOpen={openSection === 'dateRange'}
              onToggle={() => handleAccordionToggle('dateRange')}
              onDateRangeChange={memoizedOnDateRangeChange}
              dateRange={dateRange}
            />
          </div>
        </div>
      </div>
    );
  }
);

export default FilterSidebar;

interface PersonaliseSearchProps {
  onToggleChange: (isDisabled: boolean) => void;
  isDisabled: boolean;
}

//presonalise heading
const PersonaliseSearch: React.FC<PersonaliseSearchProps> = ({
  onToggleChange,
  isDisabled,
}) => {
  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onToggleChange(event.target.checked);
  };

  return (
    <div className="mb-2 bg-neutral-100 h-24 p-4 rounded-md">
      <label className="flex items-center justify-between mb-2">
        <span className="font-semiBold">Personalise Search</span>
        <input
          type="checkbox"
          className="toggle-checkbox"
          checked={!isDisabled}
          onChange={handleToggleChange}
        />
      </label>
      <span className="text-sm font-regular400 text-gray-500">You’ll see </span>
      <span className="font-bold text-sm">personalised</span>
      <span className="text-sm font-regular400 text-gray-500"> results. </span>
    </div>
  );
};

// RegularSection Component
const RegularSection: React.FC<{
  keyName: string;
  item: FacetBaseResult;
  selectedItems: Set<string>;
  handleCheckboxChange: (key: string, value: string) => void;
}> = ({ keyName, item, selectedItems, handleCheckboxChange }) => {
  const itemName = item.name || 'Unnamed Item'; // Fallback for undefined name

  return (
    <div className="flex items-center">
      <input
        type="checkbox"
        id={`${keyName}-${itemName}`}
        className="absolute opacity-0 w-0 h-0"
        checked={selectedItems.has(itemName)}
        onChange={() => handleCheckboxChange(keyName, itemName)}
      />
      <label
        htmlFor={`${keyName}-${itemName}`}
        className="flex items-center cursor-pointer"
      >
        <img
          src={selectedItems.has(itemName) ? checkboxFilled : checkboxOutline}
          alt="Checkbox icon"
          className="w-5 h-5 mr-2"
        />
        <span className="font-regular">{itemName}</span>
      </label>
    </div>
  );
};

// CropSection Component
const CropSection: React.FC<{
  keyName: string;
  item: FacetBaseResult;
  selectedItems: Set<string>;
  handleCheckboxChange: (key: string, value: string) => void;
}> = ({ keyName, item, selectedItems, handleCheckboxChange }) => {
  const itemName = item.name || 'Unnamed Item'; // Fallback for undefined name

  return (
    <>
      <div className="font-bold mb-2">{itemName}</div>
      {item.childs && item.childs.length > 0 && (
        <ul className="space-y-2">
          {item.childs.map((child, childIndex) => (
            <li key={childIndex} className="flex items-center">
              <input
                type="checkbox"
                id={`${keyName}-${itemName}-${childIndex}`}
                className="absolute opacity-0 w-0 h-0"
                checked={selectedItems.has(child)}
                onChange={() => handleCheckboxChange(keyName, child)}
              />
              <label
                htmlFor={`${keyName}-${itemName}-${childIndex}`}
                className="flex items-center cursor-pointer"
              >
                <img
                  src={
                    selectedItems.has(child) ? checkboxFilled : checkboxOutline
                  }
                  alt="Checkbox icon"
                  className="w-5 h-5 mr-2"
                />
                <span className="font-regular">{child}</span>
              </label>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

const dateRangeOptions = [
  { label: 'All dates', value: DateRangeOption._0 },
  { label: 'Past week', value: DateRangeOption._1 },
  { label: 'Past month', value: DateRangeOption._2 },
  { label: 'Past year', value: DateRangeOption._3 },
  { label: 'Custom date range', value: DateRangeOption._4 },
];

interface DateRangeFilterProps {
  isOpen: boolean;
  onToggle: () => void;
  onDateRangeChange: (dateRange: DateRange) => void;
  dateRange: DateRange;
}

export const DateRangeFilter: React.FC<DateRangeFilterProps> = React.memo(
  ({ isOpen, onToggle, onDateRangeChange, dateRange }) => {
    const [selectedDateRange, setSelectedDateRange] = useState<DateRangeOption>(
      DateRangeOption._0
    );
    const [customDateRange, setCustomDateRange] = useState<CustomDateRange>({
      startDate: undefined,
      endDate: undefined,
    });

    const startDateRef = useRef<HTMLInputElement>(null);
    const endDateRef = useRef<HTMLInputElement>(null);

    // Update the UI when dateRange prop changes
    useEffect(() => {
      setSelectedDateRange(dateRange.dateRangeOption);
    }, [dateRange]);

    const handleDateRangeChange = (option: DateRangeOption) => {
      setSelectedDateRange(option);

      let dateRange: DateRange;

      switch (option) {
        case DateRangeOption._1: // this is past week
          dateRange = {
            dateRangeOption: 1,
          };
          break;

        case DateRangeOption._2: // this is past month
          dateRange = {
            dateRangeOption: 2,
          };
          break;

        case DateRangeOption._3: // this is past year
          dateRange = {
            dateRangeOption: 3,
          };
          break;

        case DateRangeOption._4: // custom date range
          const today = new Date();
          const dateOneMonthAgo = sub(today, { months: 1 }); // By default start date is 1 month ago)

          dateRange = {
            dateRangeOption: 4,
            customRange: {
              startDate: dateOneMonthAgo.toISOString(),
              endDate: today.toISOString(),
            },
          };
          break;

        case DateRangeOption._0: //default all dates
        default:
          dateRange = {
            dateRangeOption: 0,
          };
          break;
      }
      setCustomDateRange(
        dateRange.customRange || { startDate: undefined, endDate: undefined }
      );

      //notify parent
      onDateRangeChange(dateRange);
    };

    const handleCustomDateChange = (
      key: 'startDate' | 'endDate',
      value: string
    ) => {
      setCustomDateRange((prev) => {
        // Convert the selected date to the desired server-side format
        const serverFormattedDate = new Date(value).toISOString();
        const updatedRange = { ...prev, [key]: serverFormattedDate };

        // Notify parent
        onDateRangeChange({
          dateRangeOption: DateRangeOption._4,
          customRange: updatedRange,
        });

        return updatedRange;
      });
    };

    return (
      <div className="mb-6">
        {/* Accordion Header */}
        <div
          className="flex justify-between items-center cursor-pointer mb-2 w-full text-left"
          onClick={onToggle}
        >
          <div className="flex items-center">
            <img src={calendar} alt={'Date Range'} className="w-5 h-5 mr-2" />
            <span className="font-semiBold ml-4">Date Range</span>
          </div>
          <img
            src={isOpen ? chevronUp : chevronDown}
            alt="Toggle"
            className="w-4 h-4"
          />
        </div>

        {/* Display selected date range below the accordion header */}
        {selectedDateRange !== DateRangeOption._4 && (
          <div className="text-sm font-regular400 text-gray-500 mb-2 ml-11">
            {dateRangeOptions.find(
              (option) => option.value === selectedDateRange
            )?.label || ''}
          </div>
        )}

        {/* Display Selected Custom Date Range and Clear Button */}
        {(customDateRange.startDate || customDateRange.endDate) &&
          selectedDateRange === DateRangeOption._4 && (
            <div className="mb-2 ml-11">
              <span className="block text-sm font-regular400 text-gray-500 mb-2">
                {customDateRange.startDate
                  ? formatDate(customDateRange.startDate)
                  : ''}
                {customDateRange.endDate
                  ? ` - ${formatDate(customDateRange.endDate)}`
                  : ''}
              </span>
            </div>
          )}

        {/* Accordion Content */}
        {isOpen && (
          <div className="space-y-3">
            {dateRangeOptions.map((option) => (
              <div key={option.value} className="flex items-center ml-11">
                <input
                  type="radio"
                  id={`dateRange-${option.value}`}
                  name="dateRange"
                  className="hidden"
                  checked={selectedDateRange === option.value}
                  onChange={() => handleDateRangeChange(option.value)}
                />

                <label
                  htmlFor={`dateRange-${option.value}`}
                  className="flex items-center cursor-pointer"
                >
                  <img
                    src={
                      selectedDateRange === option.value
                        ? radioButtonChecked
                        : radioButtonUnchecked
                    }
                    alt={
                      selectedDateRange === option.value
                        ? 'Checked'
                        : 'Unchecked'
                    }
                    className="w-5 h-5 mr-2"
                  />
                  <span className="font-regular">{option.label}</span>
                </label>
              </div>
            ))}

            {/* Custom Date Inputs */}
            {selectedDateRange === DateRangeOption._4 && (
              <div className="mt-4 space-y-4 ml-11">
                <div className="relative">
                  <label htmlFor="start-date" className="block font-bold mb-1">
                    Start date
                  </label>
                  <div
                    className="relative flex items-center cursor-pointer"
                    onClick={() => startDateRef.current?.showPicker()}
                  >
                    <input
                      type="date"
                      id="start-date"
                      ref={startDateRef}
                      className="top-0 left-0 w-full border rounded p-2 cursor-pointer"
                      value={
                        customDateRange.startDate
                          ? formatDateForInput(customDateRange.startDate)
                          : formatDateForInput(
                              sub(new Date(), { months: 1 }).toISOString()
                            )
                      }
                      onChange={(e) =>
                        handleCustomDateChange(
                          'startDate',
                          e.target.value ||
                            sub(new Date(), { months: 1 }).toISOString()
                        )
                      }
                    />
                  </div>
                </div>

                <div className="relative">
                  <label htmlFor="end-date" className="block font-bold mb-1">
                    End date
                  </label>
                  <div
                    className="flex items-center"
                    onClick={() => endDateRef.current?.showPicker()}
                  >
                    <input
                      type="date"
                      id="end-date"
                      ref={endDateRef}
                      className="w-full border rounded p-2 cursor-pointer"
                      value={
                        customDateRange.endDate
                          ? formatDateForInput(customDateRange.endDate)
                          : formatDateForInput(new Date().toISOString())
                      }
                      onChange={(e) =>
                        handleCustomDateChange(
                          'endDate',
                          e.target.value || new Date().toISOString()
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
);

//mapping the image
export const imageMap: { [key: string]: string } = {
  region: location,
  cropType: wheat,
  farmManagement: tractor,
  contentType: document,
  eventType: event,
  cropAndSoilManagement: soilManagement,
  pestsWeedsDiseases: pest,
  sourceWebsite: web,
};

interface ImageIconProps {
  keyName: string;
  altText?: string;
  isGreyedOut?: boolean;
}

export const ImageIcon: React.FC<ImageIconProps> = ({
  keyName,
  altText = '',
  isGreyedOut = false,
}) => {
  const imageSrc = imageMap[keyName];
  return imageSrc ? (
    <img
      src={imageSrc}
      alt={altText || keyName}
      className={`"w-5 h-5 mr-2" ${isGreyedOut ? 'opacity-40' : ''}`}
    />
  ) : null;
};
