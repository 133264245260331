const LatestReleasesSkeletons: React.FC = () => {
  const skeletonCards = Array.from({ length: 3 }); // Array to create 3 skeleton cards

  const SkeletonCard = () => (
    <div className="bg-secondaryTeal-100 bg-cover bg-no-repeat flex flex-col justify-between p-4 border bg-shimmer animate-shimmer rounded-lg h-[150px]"></div>
  );

  return (
    <div className="w-full pt-16 pb-24">
      <div className="container mx-auto px-4 sm:px-6 md:px-8 lg:px-4">
        {/* Header Section */}
        <div className="flex justify-between items-center mb-8">
          <div className="bg-secondaryTeal-100 bg-cover bg-no-repeat h-8 w-56 sm:w-80 rounded bg-shimmer animate-shimmer"></div>
          <div className="bg-secondaryTeal-100 bg-cover bg-no-repeat hidden lg:block h-10 w-40 rounded bg-shimmer animate-shimmer"></div>
        </div>

        {/* Skeleton grid for releases */}
        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 pb-8 sm:pb-12">
          {skeletonCards.map((_, index) => (
            <SkeletonCard key={index} />
          ))}
        </div>
      </div>
      {/* Button Section for smaller screens */}
      <div className="flex justify-center lg:hidden px-6">
        <div className="px-6 py-3 bg-secondaryTeal-100 bg-cover bg-no-repeat font-bold rounded-md bg-shimmer animate-shimmer h-10 w-full"></div>
      </div>
    </div>
  );
};

export default LatestReleasesSkeletons;
